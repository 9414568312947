import {
  ADD_RULE,
  ADD_RULE_FAIL,
  ADD_RULE_SUCCESS,
  ADD_SHARED_COLLECTION,
  ADD_SHARED_COLLECTION_FAIL,
  ADD_SHARED_COLLECTION_SUCCESS,
  CLEAR_REDUX_COLLECTIONS_STATE,
  CREATE_INITIAL_SPHERES,
  CREATE_INITIAL_SPHERES_FAIL,
  CREATE_INITIAL_SPHERES_SUCCESS,
  CREATE_SPHERE,
  CREATE_SPHERE_FAIL,
  CREATE_SPHERE_SUCCESS,
  DELETE_COLLECTION_SHARING,
  DELETE_COLLECTION_SHARING_FAIL,
  DELETE_COLLECTION_SHARING_SUCCESS,
  DELETE_SPHERE,
  DELETE_SPHERE_FAIL,
  DELETE_SPHERE_SUCCESS,
  FETCH_COLLECTION,
  FETCH_COLLECTION_FAIL,
  FETCH_COLLECTION_SUCCESS,
  FETCH_COMPANIES,
  FETCH_COMPANIES_FAIL,
  FETCH_COMPANIES_SUCCESS,
  FETCH_CONNEXIONS,
  FETCH_CONNEXIONS_FAIL,
  FETCH_CONNEXIONS_SUCCESS,
  FETCH_EXPERTISES,
  FETCH_EXPERTISES_FAIL,
  FETCH_EXPERTISES_SUCCESS,
  FETCH_FLOW_SPHERE,
  FETCH_FLOW_SPHERE_FAIL,
  FETCH_FLOW_SPHERE_SUCCESS,
  FETCH_LOCATIONS,
  FETCH_LOCATIONS_FAIL,
  FETCH_LOCATIONS_SUCCESS,
  FETCH_POPULAR_COMPANIES,
  FETCH_POPULAR_COMPANIES_SUCCESS,
  FETCH_POPULAR_EXPERTISES,
  FETCH_POPULAR_EXPERTISES_SUCCESS,
  FETCH_POPULAR_LOCATIONS,
  FETCH_POPULAR_LOCATIONS_SUCCESS,
  FETCH_POPULAR_SOURCES,
  FETCH_POPULAR_SOURCES_SUCCESS,
  FETCH_POPULAR_TOPICS,
  FETCH_POPULAR_TOPICS_SUCCESS,
  FETCH_SOURCES,
  FETCH_SOURCES_FAIL,
  FETCH_SOURCES_SUCCESS,
  FETCH_SPHERES,
  FETCH_SPHERES_FAIL,
  FETCH_SPHERES_SUCCESS,
  FETCH_STAGE_SPHERE,
  FETCH_STAGE_SPHERE_FAIL,
  FETCH_STAGE_SPHERE_SUCCESS,
  FETCH_TOPICS,
  FETCH_TOPICS_FAIL,
  FETCH_TOPICS_SUCCESS,
  MANAGE_GLOBAL_SHARING,
  MANAGE_GLOBAL_SHARING_FAIL,
  MANAGE_GLOBAL_SHARING_SUCCESS,
  MANAGE_PERSONAL_SHARING,
  MANAGE_PERSONAL_SHARING_FAIL,
  MANAGE_PERSONAL_SHARING_SUCCESS,
  MANAGE_TEAM_SHARING,
  MANAGE_TEAM_SHARING_FAIL,
  MANAGE_TEAM_SHARING_SUCCESS,
  REMOVE_SHARED_COLLECTION,
  REMOVE_SHARED_COLLECTION_FAIL,
  REMOVE_SHARED_COLLECTION_SUCCESS,
  RENAME_SPHERE,
  RENAME_SPHERE_FAIL,
  RENAME_SPHERE_SUCCESS,
  REORDER_SPHERES,
  REORDER_SPHERES_FAIL,
  REORDER_SPHERES_SUCCESS,
  SAVE_FLOW_SPHERE,
  SAVE_FLOW_SPHERE_FAIL,
  SAVE_FLOW_SPHERE_SUCCESS,
  SAVE_STAGE_SPHERE,
  SAVE_STAGE_SPHERE_FAIL,
  SAVE_STAGE_SPHERE_SUCCESS,
  SET_INITIAL_REDUX_COLLECTIONS_STATE,
  UPDATE_RULE,
  UPDATE_RULE_FAIL,
  UPDATE_RULE_SUCCESS,
} from "./Collections.types"

export const fetchSpheres = () => {
  return {
    type: FETCH_SPHERES,
  }
}

export const fetchSpheresSuccess = (
  spheres,
  spheres_order,
  shared_spheres,
  team_shared_spheres
) => {
  return {
    type: FETCH_SPHERES_SUCCESS,
    spheres: spheres,
    spheres_order: spheres_order,
    shared_spheres: shared_spheres,
    team_shared_spheres: team_shared_spheres,
  }
}

export const fetchSpheresFail = (error) => {
  return {
    type: FETCH_SPHERES_FAIL,
    error: error,
  }
}

export const deleteSphere = (id) => {
  return {
    type: DELETE_SPHERE,
    id: id,
  }
}

export const deleteSphereSuccess = (sphere) => {
  return {
    type: DELETE_SPHERE_SUCCESS,
    sphere: sphere,
  }
}

export const deleteSphereFail = (error) => {
  return {
    type: DELETE_SPHERE_FAIL,
    error: error,
  }
}

export const reorderSpheres = (reordered_spheres_ids) => {
  return {
    type: REORDER_SPHERES,
    reordered_spheres_ids: reordered_spheres_ids,
  }
}

export const reorderSpheresSuccess = (spheres_order) => {
  return {
    type: REORDER_SPHERES_SUCCESS,
    spheres_order: spheres_order,
  }
}

export const reorderSpheresFail = (error) => {
  return {
    type: REORDER_SPHERES_FAIL,
    error: error,
  }
}

export const renameSphere = (id, new_title) => {
  return {
    type: RENAME_SPHERE,
    id: id,
    new_title: new_title,
  }
}

export const renameSphereSuccess = () => {
  return {
    type: RENAME_SPHERE_SUCCESS,
  }
}

export const renameSphereFail = (error) => {
  return {
    type: RENAME_SPHERE_FAIL,
    error: error,
  }
}

export const fetchTopics = () => {
  return {
    type: FETCH_TOPICS,
  }
}

export const fetchTopicsSuccess = (topics) => {
  return {
    type: FETCH_TOPICS_SUCCESS,
    topics: topics,
  }
}

export const fetchTopicsFail = (error) => {
  return {
    type: FETCH_TOPICS_FAIL,
    error: error,
  }
}

export const fetchExpertises = () => {
  return {
    type: FETCH_EXPERTISES,
  }
}

export const fetchExpertisesSuccess = (expertises) => {
  return {
    type: FETCH_EXPERTISES_SUCCESS,
    expertises: expertises,
  }
}

export const fetchExpertisesFail = (error) => {
  return {
    type: FETCH_EXPERTISES_FAIL,
    error: error,
  }
}

export const fetchPopularExpertises = (status = [2]) => {
  return {
    type: FETCH_POPULAR_EXPERTISES,
    status: status,
  }
}

export const fetchPopularExpertisesSuccess = (expertises) => {
  return {
    type: FETCH_POPULAR_EXPERTISES_SUCCESS,
    expertises: expertises,
  }
}

export const fetchPopularTopics = (status = [2]) => {
  return {
    type: FETCH_POPULAR_TOPICS,
    status: status,
  }
}

export const fetchPopularTopicsSuccess = (topics) => {
  return {
    type: FETCH_POPULAR_TOPICS_SUCCESS,
    topics: topics,
  }
}

export const fetchCompanies = () => {
  return {
    type: FETCH_COMPANIES,
  }
}

export const fetchCompaniesSuccess = (companies) => {
  return {
    type: FETCH_COMPANIES_SUCCESS,
    companies: companies,
  }
}

export const fetchCompaniesFail = (error) => {
  return {
    type: FETCH_COMPANIES_FAIL,
    error: error,
  }
}

export const fetchPopularCompanies = (status = [2]) => {
  return {
    type: FETCH_POPULAR_COMPANIES,
    status: status,
  }
}

export const fetchPopularCompaniesSuccess = (companies) => {
  return {
    type: FETCH_POPULAR_COMPANIES_SUCCESS,
    companies: companies,
  }
}

export const fetchLocations = () => {
  return {
    type: FETCH_LOCATIONS,
  }
}

export const fetchLocationsSuccess = (locations) => {
  return {
    type: FETCH_LOCATIONS_SUCCESS,
    locations: locations,
  }
}

export const fetchLocationsFail = (error) => {
  return {
    type: FETCH_LOCATIONS_FAIL,
    error: error,
  }
}

export const fetchPopularLocations = (status = [2]) => {
  return {
    type: FETCH_POPULAR_LOCATIONS,
    status: status,
  }
}

export const fetchPopularLocationsSuccess = (locations) => {
  return {
    type: FETCH_POPULAR_LOCATIONS_SUCCESS,
    locations: locations,
  }
}

export const fetchConnexions = () => {
  return {
    type: FETCH_CONNEXIONS,
  }
}

export const fetchConnexionsSuccess = (connexions) => {
  return {
    type: FETCH_CONNEXIONS_SUCCESS,
    connexions: connexions,
  }
}

export const fetchConnexionsFail = (error) => {
  return {
    type: FETCH_CONNEXIONS_FAIL,
    error: error,
  }
}

export const fetchSources = () => {
  return {
    type: FETCH_SOURCES,
  }
}

export const fetchSourcesSuccess = (sources) => {
  return {
    type: FETCH_SOURCES_SUCCESS,
    sources: sources,
  }
}

export const fetchSourcesFail = (error) => {
  return {
    type: FETCH_SOURCES_FAIL,
    error: error,
  }
}

export const fetchPopularSources = (status = [2]) => {
  return {
    type: FETCH_POPULAR_SOURCES,
    status: status,
  }
}

export const fetchPopularSourcesSuccess = (sources) => {
  return {
    type: FETCH_POPULAR_SOURCES_SUCCESS,
    sources: sources,
  }
}

export const addRule = (sphere_id, rule_type, settings) => {
  return {
    type: ADD_RULE,
    sphere_id: sphere_id,
    rule_type: rule_type,
    settings: settings,
  }
}

export const addRuleSuccess = (rule) => {
  return {
    type: ADD_RULE_SUCCESS,
    rule: rule,
  }
}

export const addRuleFail = (error) => {
  return {
    type: ADD_RULE_FAIL,
    error: error,
  }
}

export const updateRule = (sphere_id, enabled, rule_id, settings) => {
  return {
    type: UPDATE_RULE,
    enabled: enabled,
    sphere_id: sphere_id,
    rule_id: rule_id,
    settings: settings,
  }
}

export const updateRuleSuccess = (rule) => {
  return {
    type: UPDATE_RULE_SUCCESS,
    rule: rule,
  }
}

export const updateRuleFail = (error) => {
  return {
    type: UPDATE_RULE_FAIL,
    error: error,
  }
}

export const clearReduxCollectionsState = () => {
  return {
    type: CLEAR_REDUX_COLLECTIONS_STATE,
  }
}

export const setInitialReduxCollectionsState = () => {
  return {
    type: SET_INITIAL_REDUX_COLLECTIONS_STATE,
  }
}

export const fetchCollection = (type, collection_id) => {
  return {
    type: FETCH_COLLECTION,
    collection_id: collection_id,
    collection_type: type,
  }
}

export const fetchCollectionSuccess = (
  collection,
  collectionSharings,
  teamCollectionSharings
) => {
  return {
    type: FETCH_COLLECTION_SUCCESS,
    collection: collection,
    collectionSharings: collectionSharings,
    teamCollectionSharings: teamCollectionSharings,
  }
}

export const fetchCollectionFail = (error) => {
  return {
    type: FETCH_COLLECTION_FAIL,
    error: error,
  }
}

export const managePersonalSharing = (collectionSharing, type) => {
  return {
    type: MANAGE_PERSONAL_SHARING,
    collection_type: type,
    collection_id: collectionSharing.collection_id,
    email: collectionSharing.email,
    access_level: collectionSharing.access_level,
  }
}

export const managePersonalSharingSuccess = (collectionSharing) => {
  return {
    type: MANAGE_PERSONAL_SHARING_SUCCESS,
    collectionSharing,
  }
}

export const managePersonalSharingFail = (error) => {
  return {
    type: MANAGE_PERSONAL_SHARING_FAIL,
    error: error,
  }
}

export const manageTeamSharing = (collectionSharing, type) => {
  return {
    type: MANAGE_TEAM_SHARING,
    collection_type: type,
    collection_id: collectionSharing.collection_id,
    team_id: collectionSharing.team_id,
    access_level: collectionSharing.access_level,
  }
}

export const manageTeamSharingSuccess = (teamCollectionSharings) => {
  return {
    type: MANAGE_TEAM_SHARING_SUCCESS,
    team_collection_sharings: teamCollectionSharings,
  }
}

export const manageTeamSharingFail = (error) => {
  return {
    type: MANAGE_TEAM_SHARING_FAIL,
    error: error,
  }
}

export const manageGlobalSharing = (collectionSharing, type) => {
  return {
    type: MANAGE_GLOBAL_SHARING,
    collection_type: type,
    collection_id: collectionSharing.collection_id,
    global: collectionSharing.global,
    access_level: collectionSharing.access_level,
  }
}

export const manageGlobalSharingSuccess = (collectionSharing) => {
  return {
    type: MANAGE_GLOBAL_SHARING_SUCCESS,
    collectionSharing,
  }
}

export const manageGlobalSharingFail = (error) => {
  return {
    type: MANAGE_GLOBAL_SHARING_FAIL,
    error: error,
  }
}

export const deleteCollectionSharing = (collectionSharingId) => {
  return {
    type: DELETE_COLLECTION_SHARING,
    collection_sharing_id: collectionSharingId,
  }
}

export const deleteCollectionSharingSuccess = (id) => {
  return {
    type: DELETE_COLLECTION_SHARING_SUCCESS,
    collection_sharing_id: id,
  }
}

export const deleteCollectionSharingFail = (error) => {
  return {
    type: DELETE_COLLECTION_SHARING_FAIL,
    error: error,
  }
}
export const addSharedCollection = (token) => {
  return {
    type: ADD_SHARED_COLLECTION,
    sharing_token: token,
  }
}

export const addSharedCollectionSuccess = () => {
  return {
    type: ADD_SHARED_COLLECTION_SUCCESS,
  }
}

export const addSharedCollectionFail = (error) => {
  return {
    type: ADD_SHARED_COLLECTION_FAIL,
    error: error,
  }
}
export const removeSharedCollection = (collection_id) => {
  return {
    type: REMOVE_SHARED_COLLECTION,
    collection_id: collection_id,
  }
}

export const removeSharedCollectionSuccess = () => {
  return {
    type: REMOVE_SHARED_COLLECTION_SUCCESS,
  }
}

export const removeSharedCollectionFail = (error) => {
  return {
    type: REMOVE_SHARED_COLLECTION_FAIL,
    error: error,
  }
}

export const createSphere = (type, name = null) => {
  return {
    type: CREATE_SPHERE,
    sphere_type: type,
    sphere_name: name,
  }
}

export const createSphereSuccess = () => {
  return {
    type: CREATE_SPHERE_SUCCESS,
  }
}

export const createSphereFail = (error) => {
  return {
    type: CREATE_SPHERE_FAIL,
    error: error,
  }
}
export const saveFlowSphereAction = (sphere_id, steps, steps_to_remove) => {
  return {
    type: SAVE_FLOW_SPHERE,
    sphere_id: sphere_id,
    sphere_steps: steps,
    steps_to_remove: steps_to_remove,
  }
}

export const saveFlowSphereSuccess = (flow_sphere) => {
  return {
    type: SAVE_FLOW_SPHERE_SUCCESS,
    flow_sphere: flow_sphere,
  }
}

export const saveFlowSphereFail = (error) => {
  return {
    type: SAVE_FLOW_SPHERE_FAIL,
    error: error,
  }
}

export const fetchFlowSphere = (sphere_id) => {
  return {
    type: FETCH_FLOW_SPHERE,
    sphere_id: sphere_id,
  }
}

export const fetchFlowSphereSuccess = (flow_sphere) => {
  return {
    type: FETCH_FLOW_SPHERE_SUCCESS,
    flow_sphere: flow_sphere,
  }
}

export const fetchFlowSphereFail = (error) => {
  return {
    type: FETCH_FLOW_SPHERE_FAIL,
    error: error,
  }
}

export const fetchStageSphere = (sphere_id) => {
  return {
    type: FETCH_STAGE_SPHERE,
    sphere_id: sphere_id,
  }
}

export const fetchStageSphereSuccess = (stage_sphere) => {
  return {
    type: FETCH_STAGE_SPHERE_SUCCESS,
    stage_sphere: stage_sphere,
  }
}

export const fetchStageSphereFail = (error) => {
  return {
    type: FETCH_STAGE_SPHERE_FAIL,
    error: error,
  }
}

export const saveStageSphereAction = (sphere_id, steps) => {
  return {
    type: SAVE_STAGE_SPHERE,
    sphere_id: sphere_id,
    sphere_steps: steps,
  }
}

export const saveStageSphereSuccess = (stage_sphere) => {
  return {
    type: SAVE_STAGE_SPHERE_SUCCESS,
    stage_sphere: stage_sphere,
  }
}

export const saveStageSphereFail = (error) => {
  return {
    type: SAVE_STAGE_SPHERE_FAIL,
    error: error,
  }
}

export const createInitialSpheres = (spheres) => {
  return {
    type: CREATE_INITIAL_SPHERES,
    spheres: spheres,
  }
}

export const createInitialSpheresSuccess = () => {
  return {
    type: CREATE_INITIAL_SPHERES_SUCCESS,
  }
}

export const createInitialSpheresFail = (error) => {
  return {
    type: CREATE_INITIAL_SPHERES_FAIL,
    error: error,
  }
}
