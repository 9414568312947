import { useHistory, useParams } from "react-router"
import React, { useEffect, useState } from "react"
import SharedTable from "../../components/SharedCollection/SharedTable.component"
import LoginPageBackground from "./../../images/LoginPageBackground.svg"
import "./SharedCollection.styles.scss"
import { Avatar, Button } from "antd"
import { UserOutlined } from "@ant-design/icons"
import { fetchSharingDetails } from "./SharedCollection.api"
import { Link } from "react-router-dom"
import RelatableLoader from "../../components/UI/RelatableLoader/RelatableLoader.component"

const SharedCollectionPage = ({ isAuthorized }) => {
  const params = useParams()
  const history = useHistory()
  const [sharingDetails, setSharingDetails] = useState(null)
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    //    load token and user
    fetchSharingDetails(params.token, (sharingDetails) => {
      if (isAuthorized) {
        history.push(`/database/shared/${params.token}`)
      } else if (!sharingDetails.global) {
        history.push(
          `/login?collection_title=${sharingDetails.title}&shared_by=${sharingDetails.shared_by}`,
          { from: history.location.pathname }
        )
      } else {
        setSharingDetails(sharingDetails)
      }
      setLoaded(true)
    }).catch((error) => {
      if (error.response.status === 404) {
        setLoaded(true)
        setSharingDetails(null)
      }
    })
    // eslint-disable-next-line
  }, [params.token])

  return (
    <div className={"SharedCollection"}>
      {loaded ? (
        sharingDetails ? (
          <div className={"SharedCollection_Wrapper"}>
            <div className={"SharedCollection_Header"}>
              <img
                className="SharedCollection_Logo"
                src="../primaryv2.svg"
                alt="Relatable"
              />
              <span className={"SharedCollection_Title"}>
                {sharingDetails.title}{" "}
              </span>
              <div className={"SharedCollection_Owner"}>
                <Avatar icon={<UserOutlined />} />

                <span>{sharingDetails.shared_by}</span>
              </div>
              {!sharingDetails.team_id && (
                <Button
                  type={"primary"}
                  className={"SharedCollection_AddButton"}
                  onClick={() =>
                    history.push(`/login`, { from: history.location.pathname })
                  }
                >
                  Add to my Relatable
                </Button>
              )}
            </div>

            <SharedTable
              activeCollection={sharingDetails}
              token={params.token}
              global={true}
            />
          </div>
        ) : (
          <div className={"SharedCollection_NotFoundWrapper"}>
            <div className={"SharedCollection_Header"}>
              <span className={"SharedCollection_Title"}>
                We couldn’t find that Sphere - You might need to{" "}
                <Link to={"/login"}>log</Link> in or{" "}
                <Link to={"/signup"}>register</Link> first.
              </span>
            </div>
          </div>
        )
      ) : (
        <RelatableLoader size={"large"} style={{ margin: "auto" }} />
      )}
      <div className="SharedCollection_BackgroundImage">
        <img src={LoginPageBackground} alt="Background" />
      </div>
    </div>
  )
}

export default SharedCollectionPage
