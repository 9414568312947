export const FETCH_SPHERES = "FETCH_SPHERES"
export const FETCH_SPHERES_SUCCESS = "FETCH_SPHERES_SUCCESS"
export const FETCH_SPHERES_FAIL = "FETCH_SPHERES_FAIL"

export const DELETE_SPHERE = "DELETE_SPHERE"
export const DELETE_SPHERE_SUCCESS = "DELETE_SPHERE_SUCCESS"
export const DELETE_SPHERE_FAIL = "DELETE_SPHERE_FAIL"

export const REORDER_SPHERES = "REORDER_SPHERES"
export const REORDER_SPHERES_SUCCESS = "REORDER_SPHERES_SUCCESS"
export const REORDER_SPHERES_FAIL = "REORDER_SPHERES_FAIL"

export const RENAME_SPHERE = "RENAME_SPHERE"
export const RENAME_SPHERE_SUCCESS = "RENAME_SPHERE_SUCCESS"
export const RENAME_SPHERE_FAIL = "RENAME_SPHERE_FAIL"

export const FETCH_TOPICS = "FETCH_TOPICS"
export const FETCH_TOPICS_SUCCESS = "FETCH_TOPICS_SUCCESS"
export const FETCH_TOPICS_FAIL = "FETCH_TOPICS_FAIL"

export const FETCH_EXPERTISES = "FETCH_EXPERTISES"
export const FETCH_EXPERTISES_SUCCESS = "FETCH_EXPERTISES_SUCCESS"
export const FETCH_EXPERTISES_FAIL = "FETCH_EXPERTISES_FAIL"

export const FETCH_POPULAR_EXPERTISES = "FETCH_POPULAR_EXPERTISES"
export const FETCH_POPULAR_EXPERTISES_SUCCESS = "FETCH_POPULAR_EXPERTISES_SUCCESS"

export const FETCH_POPULAR_TOPICS = "FETCH_POPULAR_TOPICS"
export const FETCH_POPULAR_TOPICS_SUCCESS = "FETCH_POPULAR_TOPICS_SUCCESS"

export const FETCH_COMPANIES = "FETCH_COMPANIES"
export const FETCH_COMPANIES_SUCCESS = "FETCH_COMPANIES_SUCCESS"
export const FETCH_COMPANIES_FAIL = "FETCH_COMPANIES_FAIL"

export const FETCH_POPULAR_COMPANIES = "FETCH_POPULAR_COMPANIES"
export const FETCH_POPULAR_COMPANIES_SUCCESS = "FETCH_POPULAR_COMPANIES_SUCCESS"

export const FETCH_LOCATIONS = "FETCH_LOCATIONS"
export const FETCH_LOCATIONS_SUCCESS = "FETCH_LOCATIONS_SUCCESS"
export const FETCH_LOCATIONS_FAIL = "FETCH_LOCATIONS_FAIL"

export const FETCH_POPULAR_LOCATIONS = "FETCH_POPULAR_LOCATIONS"
export const FETCH_POPULAR_LOCATIONS_SUCCESS = "FETCH_POPULAR_LOCATIONS_SUCCESS"

export const ADD_RULE = "ADD_RULE"
export const ADD_RULE_SUCCESS = "ADD_RULE_SUCCESS"
export const ADD_RULE_FAIL = "ADD_RULE_FAIL"

export const UPDATE_RULE = "UPDATE_RULE"
export const UPDATE_RULE_SUCCESS = "UPDATE_RULE_SUCCESS"
export const UPDATE_RULE_FAIL = "UPDATE_RULE_FAIL"

export const CLEAR_REDUX_COLLECTIONS_STATE = "CLEAR_REDUX_COLLECTIONS_STATE"
export const SET_INITIAL_REDUX_COLLECTIONS_STATE =
  "SET_INITIAL_REDUX_COLLECTIONS_STATE"

export const FETCH_COLLECTION = "FETCH_COLLECTION"
export const FETCH_COLLECTION_SUCCESS = "FETCH_COLLECTION_SUCCESS"
export const FETCH_COLLECTION_FAIL = "FETCH_COLLECTION_FAIL"

export const MANAGE_PERSONAL_SHARING = "MANAGE_PERSONAL_SHARING"
export const MANAGE_PERSONAL_SHARING_SUCCESS = "MANAGE_PERSONAL_SHARING_SUCCESS"
export const MANAGE_PERSONAL_SHARING_FAIL = "MANAGE_PERSONAL_SHARING_FAIL"
export const MANAGE_TEAM_SHARING = "MANAGE_TEAM_SHARING"
export const MANAGE_TEAM_SHARING_SUCCESS = "MANAGE_TEAM_SHARING_SUCCESS"
export const MANAGE_TEAM_SHARING_FAIL = "MANAGE_TEAM_SHARING_FAIL"

export const MANAGE_GLOBAL_SHARING = "MANAGE_GLOBAL_SHARING"
export const MANAGE_GLOBAL_SHARING_SUCCESS = "MANAGE_GLOBAL_SHARING_SUCCESS"
export const MANAGE_GLOBAL_SHARING_FAIL = "MANAGE_GLOBAL_SHARING_FAIL"

export const DELETE_COLLECTION_SHARING = "DELETE_COLLECTION_SHARING"
export const DELETE_COLLECTION_SHARING_SUCCESS = "DELETE_COLLECTION_SHARING_SUCCESS"
export const DELETE_COLLECTION_SHARING_FAIL = "DELETE_COLLECTION_SHARING_FAIL"
export const ADD_SHARED_COLLECTION = "ADD_SHARED_COLLECTION"
export const ADD_SHARED_COLLECTION_SUCCESS = "ADD_SHARED_COLLECTION_SUCCESS"
export const ADD_SHARED_COLLECTION_FAIL = "ADD_SHARED_COLLECTION_FAIL"
export const REMOVE_SHARED_COLLECTION = "REMOVE_SHARED_COLLECTION"
export const REMOVE_SHARED_COLLECTION_SUCCESS = "REMOVE_SHARED_COLLECTION_SUCCESS"
export const REMOVE_SHARED_COLLECTION_FAIL = "REMOVE_SHARED_COLLECTION_FAIL"

export const CREATE_SPHERE = "CREATE_SPHERE"
export const CREATE_SPHERE_SUCCESS = "CREATE_SPHERE_SUCCESS"
export const CREATE_SPHERE_FAIL = "CREATE_SPHERE_FAIL"

export const SAVE_FLOW_SPHERE = "SAVE_FLOW_SPHERE"
export const SAVE_FLOW_SPHERE_SUCCESS = "SAVE_FLOW_SPHERE_SUCCESS"
export const SAVE_FLOW_SPHERE_FAIL = "SAVE_FLOW_SPHERE_FAIL"

export const FETCH_FLOW_SPHERE = "FETCH_FLOW_SPHERE"
export const FETCH_FLOW_SPHERE_SUCCESS = "FETCH_FLOW_SPHERE_SUCCESS"
export const FETCH_FLOW_SPHERE_FAIL = "FETCH_FLOW_SPHERE_FAIL"

export const FETCH_STAGE_SPHERE = "FETCH_STAGE_SPHERE"
export const FETCH_STAGE_SPHERE_SUCCESS = "FETCH_STAGE_SPHERE_SUCCESS"
export const FETCH_STAGE_SPHERE_FAIL = "FETCH_STAGE_SPHERE_FAIL"

export const SAVE_STAGE_SPHERE = "SAVE_STAGE_SPHERE"
export const SAVE_STAGE_SPHERE_SUCCESS = "SAVE_STAGE_SPHERE_SUCCESS"
export const SAVE_STAGE_SPHERE_FAIL = "SAVE_STAGE_SPHERE_FAIL"

export const CREATE_INITIAL_SPHERES = "CREATE_INITIAL_SPHERES"
export const CREATE_INITIAL_SPHERES_SUCCESS = "CREATE_INITIAL_SPHERES_SUCCESS"
export const CREATE_INITIAL_SPHERES_FAIL = "CREATE_INITIAL_SPHERES_FAIL"

export const FETCH_CONNEXIONS = "FETCH_CONNEXIONS"
export const FETCH_CONNEXIONS_SUCCESS = "FETCH_CONNEXIONS_SUCCESS"
export const FETCH_CONNEXIONS_FAIL = "FETCH_CONNEXIONS_FAIL"

export const FETCH_SOURCES = "FETCH_SOURCES"
export const FETCH_SOURCES_SUCCESS = "FETCH_SOURCES_SUCCESS"
export const FETCH_SOURCES_FAIL = "FETCH_SOURCES_FAIL"

export const FETCH_POPULAR_SOURCES = "FETCH_POPULAR_SOURCES"
export const FETCH_POPULAR_SOURCES_SUCCESS = "FETCH_POPULAR_SOURCES_SUCCESS"
