import React, { useEffect, useState } from "react"
import { CSSTransition } from "react-transition-group"
import { Alert, Drawer, notification, Tooltip } from "antd"
import "../../components/Dashboard/ContactProfileDrawer/ContactProfileDrawer.styles.scss"
import { useDispatch, useSelector } from "react-redux"
import moment from "moment"
import { useFormik } from "formik"
import * as yup from "yup"
import { editNoteClear } from "../../redux/Interactions/Interactions.actions"
import ContactImageInput from "../../components/Dashboard/ContactProfileDrawer/components/ContactImageInput/ContactImageInput.component"
import Icon from "@ant-design/icons"

import CaretLeftIcon from "../../components/Icons/CaretLeft.icons"
import ContactDrawerNavSections from "../../components/Dashboard/ContactProfileDrawer/components/ContactDrawerNavSections/ContactDrawerNavSections.component"
import HeaderTitlesContactDrawer from "../../components/Dashboard/ContactProfileDrawer/components/HeaderTitlesContactDrawer/HeaderTitlesContactDrawer.component"
import SectionContact from "../../components/Dashboard/ContactProfileDrawer/components/SectionContact/SectionContact.component"
import SectionNotes from "../../components/Dashboard/ContactProfileDrawer/components/SectionNotes/SectionNotes.component"
import SkeletonLoader from "../../components/Dashboard/ContactProfileDrawer/SkeletonLoader/SkeletonLoader.component"
import {
  archiveSharedPerson,
  createSharedPerson,
  fetchSharedEventsPerson,
  fetchSharedInteractionsPerson,
  fetchSharedNotesPerson,
  fetchSharedPerson,
  fetchSharedRemindersPerson,
  updateSharedContactImage,
  updateSharedPerson,
} from "./SharedCollection.api"

import CheckIcon from "../../components/Icons/Check.icons"
import ArchiveBoxIcon from "../../components/Icons/ArchiveBox.icons"
import CancelIcon from "../../components/Icons/Cancel.icons"
import PencilSimpleLineIcon from "../../components/Icons/PencilSimpleLine.icons"
import SectionActivity from "../../components/Dashboard/ContactProfileDrawer/components/SectionActivity/SectionActivity.component"
import SectionReminders from "../Dashboard/ContactProfileDrawer/components/./SectionReminders/SectionReminders.component"
import SectionDetails from "../Dashboard/ContactProfileDrawer/components/SectionDetails/SectionDetails.component"

const SharedContactDrawer = ({
  visible,
  contactId,
  handleClose,
  sharingToken,
  sharedCollection,
  global,
  loadSharedContacts,
}) => {
  const basicTabs = [
    { name: "Details", width: 54, paddingLeft: 0 },
    { name: "Contact", width: 62, paddingLeft: 330 },
  ]

  const tabs = [
    { name: "Details", width: 54, paddingLeft: 0 },
    { name: "Contact", width: 62, paddingLeft: 78 },
    { name: "Notes", width: 48, paddingLeft: 164 },
    { name: "Activity", width: 60, paddingLeft: 236 },
    { name: "Reminders", width: 72, paddingLeft: 320 },
  ]

  const activeTab = useSelector((state) => state.AppState.activeDrawerTab)
  const user = useSelector((state) => state.UserState)
  const [mode, setSharedDrawerMode] = useState("view")

  const [contactLoading, setContactLoading] = useState(false)
  const [contact, setContact] = useState(null)
  const [image, setImage] = useState(null)

  const dispatch = useDispatch()

  useEffect(() => {
    if (activeTab !== "note" && activeTab) {
      dispatch(editNoteClear())
    }
    // eslint-disable-next-line
  }, [activeTab])

  useEffect(() => {
    if (contactId) {
      setImage(null)
      setSharedDrawerMode("view")
      setContactLoading(true)
      fetchSharedPerson(contactId, sharingToken, (contact) => {
        setContact(contact)
        setContactLoading(false)
      })
    } else {
      setSharedDrawerMode("editing")
      formik.setValues({
        ...formik.initialValues,
        spheres: [{ title: sharedCollection?.title }],
      })
      setContact(null)
      setImage(null)
    }
    // eslint-disable-next-line
  }, [contactId, visible])

  useEffect(() => {
    if (contact) {
      setFormikValues(contact)
    }
    // eslint-disable-next-line
  }, [contact])

  const formatRequest = (values) => {
    return {
      first_name: values.first_name,
      last_name: values.last_name,
      full_name: [values.first_name, values.last_name].join(" "),
      one_liner: values.one_liner,
      location: values.location,
      spheres: values.spheres,
      topics: values.topics,
      companies: values.companies,
      profile_fields: values.profile_fields,
      email_addresses: values.email_addresses,
      phone_numbers: values.phone_numbers,
      addresses: values.addresses,
      links: values.links,
      birthday: values.birthday,
      special_dates: values.special_dates,
    }
  }

  const schema = yup.object().shape({
    first_name: yup.string().nullable(),
    last_name: yup.string().nullable(),
    one_liner: yup.string().nullable(),
    location: yup.string().nullable(),
    spheres: yup
      .array()
      .of(yup.object())
      .min(1, "Every relationship needs to be in one sphere"),
    topics: yup.array().of(yup.object()),
    companies: yup.array().of(yup.object()),
    profile_fields: yup.array().of(yup.object()),
    email_addresses: yup.array().of(yup.object()),
    phone_numbers: yup.array().of(yup.object()),
    addresses: yup.array().of(yup.object()),
    links: yup.array().of(yup.object()),
    birthday: yup.date().nullable(),
    special_dates: yup.array().of(yup.object()),
  })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      first_name: "",
      last_name: "",
      one_liner: "",
      location: "",
      spheres: [],
      topics: [],
      companies: [],
      profile_fields: [],
      email_addresses: [],
      phone_numbers: [],
      addresses: [],
      links: [],
      birthday: "",
      special_dates: [],
    },
    validationSchema: schema,
    onSubmit: (values) => {
      if (contactId) {
        updateSharedPerson(
          contactId,
          formatRequest(values),
          sharingToken,
          (person) => {
            notification.success({
              message: "Contact",
              description: (
                <div>
                  <span>Successfully updated contact info.</span>
                  <br />
                </div>
              ),
              className: "Notification-success clickable",
              duration: 5,
            })
            setContact(person)
            loadSharedContacts()
            setSharedDrawerMode("view")
          }
        )
      } else {
        const full_name =
          user.first_name && user.last_name
            ? `${user.first_name} ${user.last_name}`
            : user.email
        createSharedPerson(
          formatRequest(values),
          image,
          full_name,
          sharingToken,
          (person) => {
            notification.success({
              message: "Contact",
              description: (
                <div>
                  <span>Successfully created new contact.</span>
                  <br />
                </div>
              ),
              className: "Notification-success clickable",
              duration: 5,
            })
            setContact(person)
            loadSharedContacts()
            setSharedDrawerMode("view")
          }
        )
        formik.resetForm()
        handleClose()
      }
    },
  })

  const setFormikValues = (contact) => {
    formik.setValues({
      first_name: contact.first_name,
      last_name: contact.last_name,
      one_liner: contact.one_liner,
      location: contact.location,
      spheres: contact.spheres,
      topics: contact.topics,
      companies: contact.companies,
      email_addresses: contact.email_addresses,
      phone_numbers: contact.phone_numbers,
      profile_fields: contact.profile_fields,
      addresses: contact.addresses,
      links: contact.links,
      birthday: contact.birthday
        ? moment(contact.birthday).tz(user.time_zone)
        : null,
      special_dates: contact.special_dates,
    })
  }

  const handleClickArchive = () => {
    archiveSharedPerson(contactId, sharingToken, () => {
      notification.success({
        message: "Contact",
        description: (
          <div>
            <span>Successfully archived contact.</span>
            <br />
          </div>
        ),
        className: "Notification-success",
        duration: 5,
      })
      loadSharedContacts()
      setSharedDrawerMode("view")
      handleClose()
    })
  }

  const handleClickSave = () => {
    formik.submitForm().then(() => {
      if (formik.isValid) {
        setTimeout(() => {
          formik.resetForm()
        }, 1500)
      }
    })
  }
  const handleClickCancel = () => {
    handleClose()
    setTimeout(() => {
      formik.resetForm()
    }, 1500)
  }
  const handleClickEdit = () => {
    setSharedDrawerMode("editing")
    if (contact) {
      setFormikValues(contact)
    }
  }

  return (
    <Drawer
      placement="right"
      mask={false}
      closable={false}
      destroyOnClose={true}
      afterVisibleChange={(visible) => {
        if (!visible) {
          setContact(null)
          formik.resetForm()
          handleClose()
        }
      }}
      visible={visible}
      getContainer={true}
      width="430px"
    >
      <div className="ContactProfileDrawer">
        {((contact && contact.topics && !contactLoading) || mode === "editing") && (
          <>
            <div className="ContactProfileDrawer_Header">
              {sharedCollection?.access_level < 2 ? (
                <CSSTransition
                  in={!!contact}
                  timeout={1000}
                  classNames="fade"
                  appear
                >
                  <div className="ContactProfileDrawer_Header_Buttons">
                    <Tooltip title="Back" placement="left">
                      <Icon component={CaretLeftIcon} onClick={handleClose} />
                    </Tooltip>
                  </div>
                </CSSTransition>
              ) : (
                <CSSTransition
                  in={!!contact}
                  timeout={1000}
                  classNames="fade"
                  appear
                >
                  <div className="ContactProfileDrawer_Header_Buttons">
                    {mode === "editing" ? (
                      <>
                        <Tooltip title="Save" placement="left">
                          <Icon component={CheckIcon} onClick={handleClickSave} />
                        </Tooltip>
                        {contact && (
                          <Tooltip title="Archive" placement="left">
                            <Icon
                              component={ArchiveBoxIcon}
                              onClick={handleClickArchive}
                            />
                          </Tooltip>
                        )}
                        <Tooltip title="Cancel" placement="left">
                          <Icon component={CancelIcon} onClick={handleClickCancel} />
                        </Tooltip>
                      </>
                    ) : (
                      <>
                        <Tooltip title="Back" placement="left">
                          <Icon component={CaretLeftIcon} onClick={handleClose} />
                        </Tooltip>
                        <Tooltip title="Edit" placement="left">
                          <Icon
                            component={PencilSimpleLineIcon}
                            onClick={handleClickEdit}
                          />
                        </Tooltip>
                      </>
                    )}
                  </div>
                </CSSTransition>
              )}
              {!global && contact && (
                <Alert
                  className={"SharedContactDrawer_Alert"}
                  type={"info"}
                  showIcon
                  closable
                  description={
                    <span>
                      You are viewing <b>{sharedCollection?.shared_by}’s</b> record
                      for <b>{contact.first_name}</b> - you might have different
                      information in your own database.{" "}
                      {sharedCollection?.access_level === 2 && (
                        <span>
                          Any changes you make here will only be reflected in{" "}
                          <b>{sharedCollection?.shared_by}’s</b> contacts.
                        </span>
                      )}
                    </span>
                  }
                />
              )}
              <ContactImageInput
                image={image}
                setImage={setImage}
                contact={contact}
                disabled={
                  !(sharedCollection?.access_level === 2 && mode === "editing")
                }
                handleUpdateImage={(id, image) =>
                  updateSharedContactImage(id, image, sharingToken, (person) => {
                    notification.success({
                      message: "Contact",
                      description: (
                        <div>
                          <span>Successfully updated contact image.</span>
                          <br />
                        </div>
                      ),
                      className: "Notification-success",
                      duration: 5,
                    })
                    setContact(person)
                    loadSharedContacts()
                  })
                }
              />
              <CSSTransition in={!!contact} timeout={1500} classNames="fade" appear>
                <>
                  <HeaderTitlesContactDrawer
                    formik={formik}
                    contact={contact}
                    isEditing={mode === "editing"}
                  />
                  <ContactDrawerNavSections
                    containerId={"ContactDrawerSections"}
                    tabs={sharedCollection?.access_level >= 1 ? tabs : basicTabs}
                  />
                </>
              </CSSTransition>
            </div>
            <CSSTransition in={!!contact} timeout={1800} classNames="fade" appear>
              <div
                className="ContactProfileDrawer_Sections"
                id="ContactDrawerSections"
              >
                <SectionDetails
                  formik={formik}
                  mode={mode}
                  contact={contact}
                  isEditing={mode === "editing"}
                  shared={true}
                />
                <SectionContact
                  sendable={false}
                  formik={formik}
                  mode={mode}
                  contact={contact}
                />
                {sharedCollection?.access_level >= 1 && (
                  <SectionNotes
                    contact={{
                      id: contact?.id,
                      full_name:
                        contact?.first_name ||
                        contact?.last_name ||
                        (contact?.email_addresses &&
                          contact?.email_addresses[0]?.value),
                      notes: contact?.notes,
                      notes_total_entries: contact?.notes_total_entries,
                    }}
                    editable={sharedCollection?.access_level === 2}
                    hideAddButton={true}
                    loadMore={(contactId, page) =>
                      fetchSharedNotesPerson(
                        contactId,
                        sharingToken,
                        page,
                        (notes, total_entries) => {
                          setContact({
                            ...contact,
                            notes: notes,
                            notes_total_entries: total_entries,
                          })
                          setContactLoading(false)
                        }
                      )
                    }
                  />
                )}
                {sharedCollection?.access_level >= 1 && (
                  <SectionActivity
                    contact={{
                      id: contact?.id,
                      interactions: contact?.interactions,
                      interactions_total_entries:
                        contact?.interactions_total_entries,
                      events: contact?.events,
                      events_total_entries: contact?.events_total_entries,
                    }}
                    hideAddButton={true}
                    deletable={sharedCollection?.access_level === 2}
                    loadMoreInteractions={(contactId, page) =>
                      fetchSharedInteractionsPerson(
                        contactId,
                        sharingToken,
                        page,
                        (interactions, total_entries) => {
                          setContact({
                            ...contact,
                            interactions: interactions,
                            interactions_total_entries: total_entries,
                          })
                          setContactLoading(false)
                        }
                      )
                    }
                    loadMoreEvents={(contactId, page) =>
                      fetchSharedEventsPerson(
                        contactId,
                        sharingToken,
                        page,
                        (events, total_entries) => {
                          setContact({
                            ...contact,
                            events: events,
                            events_total_entries: total_entries,
                          })
                          setContactLoading(false)
                        }
                      )
                    }
                  />
                )}
                {sharedCollection?.access_level >= 1 && (
                  <SectionReminders
                    contact={{
                      id: contact?.id,
                      reminders: contact?.reminders,
                      reminders_total_entries: contact?.reminders_total_entries,
                    }}
                    hideAddButton={true}
                    loadMore={(contactId, page) =>
                      fetchSharedRemindersPerson(
                        contactId,
                        sharingToken,
                        page,
                        (reminders, total_entries) => {
                          setContact({
                            ...contact,
                            reminders: reminders,
                            reminders_total_entries: total_entries,
                          })
                          setContactLoading(false)
                        }
                      )
                    }
                  />
                )}
              </div>
            </CSSTransition>
          </>
        )}
        <SkeletonLoader active={contactLoading} />
      </div>
    </Drawer>
  )
}

export default SharedContactDrawer
