import React, { useState } from "react"
import "./UserPanel.styles.scss"
import { Modal, Select } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { setUserPanelModalVisible } from "../../../redux/App/App.actions"
import { updateProfile } from "../../../redux/User/User.actions"
import { apiHost } from "../../../config/serverConf"

const { ClipboardItem } = window
const UserPanel = () => {
  const dispatch = useDispatch()
  const [linkCopied, setLinkCopied] = useState(false)
  const user = useSelector((state) => state.UserState)
  const URL = `https://rl8bl.co/${user.public_username}`
  const [QRCopied, setQRCopied] = useState(false)

  const userPanelModalVisible = useSelector(
    (state) => state.AppState.userPanelModalVisible
  )
  const spheres = useSelector((state) => state.CollectionsState.spheres)
  const profile_filler_sphere = useSelector(
    (state) => state.UserState.profile_filler_sphere
  )
  const handleOnCancel = () => {
    dispatch(setUserPanelModalVisible(false))
  }

  const handleOnCopyURL = (setStateCopied) => {
    setStateCopied(true)
    setTimeout(() => {
      setStateCopied(false)
    }, 1000)
  }

  const copyQRToClipboard = async () => {
    const URL = `${apiHost()}${user.qr_code}`
    try {
      const copiedImage = await fetch(URL)
      const blobData = await copiedImage.blob()
      const clipboardItemInput = new ClipboardItem({ "image/png": blobData })
      navigator.clipboard.write([clipboardItemInput])
    } catch (e) {
      console.log(e)
    }
  }

  const handleOnCopyQR = (animal) => {
    copyQRToClipboard()
    setQRCopied(true)
    setTimeout(() => {
      setQRCopied(false)
    }, 1000)
  }

  const onHandleSphereChange = (id) => {
    dispatch(updateProfile({ profile_filler_sphere_id: id }))
  }

  return (
    <Modal
      closable={false}
      destroyOnClose
      footer={null}
      visible={userPanelModalVisible}
      onCancel={handleOnCancel}
      centered={true}
      width={400}
      wrapClassName="UserPanelModal"
    >
      <div className="UserPanel">
        <h2>Let people add themselves</h2>
        <p>
          By scanning the QR code or opening the link below, people can access your
          contact information, and add themselves directly to your database.
        </p>
        <div className="UserPanel_Card1">
          <div className="UserPanel_Card1_QRCode">
            <img
              alt="QR Code"
              width={243}
              height={243}
              src={`${apiHost()}${user.qr_code}`}
            />
          </div>
          <a href={URL} target="_blank" rel="noopener noreferrer">
            {URL}
          </a>
          <div className="UserPanel_Card1_Buttons">
            <button
              onClick={() => {
                navigator.clipboard.writeText(URL)
                handleOnCopyURL(setLinkCopied)
              }}
            >
              {linkCopied ? "Copied!" : "Copy Link"}
            </button>
            <button onClick={handleOnCopyQR}>
              {QRCopied ? "Copied!" : "Copy QR"}
            </button>
          </div>
        </div>
        <div className="UserPanel_Card2">
          <h2>Add new contacts to...</h2>
          <Select
            placeholder="Select a Sphere"
            optionFilterProp="children"
            onChange={onHandleSphereChange}
            defaultValue={profile_filler_sphere?.title}
            options={spheres
              ?.filter((s) => s.sphere_type === null)
              ?.map((sphere) => ({
                label: sphere.title,
                value: sphere.id,
              }))}
          />
        </div>
        <div className="UserPanel_Card3">
          <h2>My Contact Card</h2>
          <a onClick={handleOnCancel} href="account/settings">
            Edit My Contact Card
          </a>
        </div>
        <button onClick={handleOnCancel} className="UserPanel_Close">
          Close
        </button>
      </div>
    </Modal>
  )
}

export default UserPanel
