import {
  ADD_RULE,
  ADD_RULE_FAIL,
  ADD_RULE_SUCCESS,
  CLEAR_REDUX_COLLECTIONS_STATE,
  CREATE_SPHERE,
  CREATE_SPHERE_FAIL,
  DELETE_COLLECTION_SHARING_SUCCESS,
  DELETE_SPHERE_SUCCESS,
  FETCH_COLLECTION,
  FETCH_COLLECTION_FAIL,
  FETCH_COLLECTION_SUCCESS,
  FETCH_COMPANIES_FAIL,
  FETCH_COMPANIES_SUCCESS,
  FETCH_CONNEXIONS_FAIL,
  FETCH_CONNEXIONS_SUCCESS,
  FETCH_EXPERTISES_FAIL,
  FETCH_EXPERTISES_SUCCESS,
  FETCH_FLOW_SPHERE_SUCCESS,
  FETCH_LOCATIONS_FAIL,
  FETCH_LOCATIONS_SUCCESS,
  FETCH_POPULAR_COMPANIES_SUCCESS,
  FETCH_POPULAR_EXPERTISES_SUCCESS,
  FETCH_POPULAR_LOCATIONS_SUCCESS,
  FETCH_POPULAR_SOURCES_SUCCESS,
  FETCH_POPULAR_TOPICS_SUCCESS,
  FETCH_SOURCES_FAIL,
  FETCH_SOURCES_SUCCESS,
  FETCH_SPHERES,
  FETCH_SPHERES_FAIL,
  FETCH_SPHERES_SUCCESS,
  FETCH_TOPICS_FAIL,
  FETCH_TOPICS_SUCCESS,
  MANAGE_GLOBAL_SHARING_SUCCESS,
  MANAGE_PERSONAL_SHARING_SUCCESS,
  MANAGE_TEAM_SHARING_SUCCESS,
  RENAME_SPHERE_FAIL,
  RENAME_SPHERE_SUCCESS,
  REORDER_SPHERES_FAIL,
  REORDER_SPHERES_SUCCESS,
  SAVE_FLOW_SPHERE_SUCCESS,
  SET_INITIAL_REDUX_COLLECTIONS_STATE,
  UPDATE_RULE,
  UPDATE_RULE_FAIL,
  UPDATE_RULE_SUCCESS,
} from "./Collections.types"
import * as _ from "lodash"
import { LOGOUT_SUCCESS } from "../User/User.types"

const INITIAL_STATE = {
  spheres: null,
  spheres_order: null,
  topics: null,
  expertises: null,
  companies: null,
  sources: null,
  popularTopics: null,
  popularExpertises: null,
  popularCompanies: null,
  popularSources: null,
  locations: null,
  loading: false,
  collection: null,
  collectionSharings: [],
  teamCollectionSharings: [],
  sharedSpheres: null,
  team_shared_spheres: [],
  flowSphere: null,
}

const updateSpheres = (state, action) => {
  let editingSphere = state.spheres.find(
    (sphere) => sphere.id === action.rule.sphere
  )
  let otherSpheres = state.spheres.filter(
    (sphere) => sphere.id !== action.rule.sphere
  )

  editingSphere = { ...editingSphere, rules: [action.rule, ...editingSphere.rules] }

  return _.sortBy([editingSphere, ...otherSpheres], "title")
}

const updateRule = (state, action) => {
  let editingSphere = state.spheres.find(
    (sphere) => sphere.id === action.rule.sphere
  )
  let otherSpheres = state.spheres.filter(
    (sphere) => sphere.id !== action.rule.sphere
  )

  editingSphere = {
    ...editingSphere,
    rules: [
      action.rule,
      ...editingSphere.rules.filter((rule) => rule.id !== action.rule.id),
    ],
  }

  return _.sortBy([editingSphere, ...otherSpheres], "title")
}

const updateSharings = (state, action) => {
  let updatedSharings
  if (state.collectionSharings.find((s) => s.id === action.collectionSharing.id)) {
    updatedSharings = state.collectionSharings.map((obj) =>
      action.collectionSharing.id === obj.id ? action.collectionSharing : obj
    )
  } else {
    updatedSharings = [...state.collectionSharings, action.collectionSharing]
  }

  return updatedSharings
}

export const collectionsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_SPHERES:
      return {
        ...state,
        loading: true,
      }
    case CREATE_SPHERE:
      return {
        ...state,
        loading: true,
      }
    case CREATE_SPHERE_FAIL:
      return {
        ...state,
        loading: false,
      }
    case FETCH_SPHERES_SUCCESS:
      return {
        ...state,
        spheres: action.spheres,
        spheres_order: action.spheres_order,
        shared_spheres: action.shared_spheres,
        team_shared_spheres: _.uniqBy(
          action.team_shared_spheres.sort((a, b) =>
            a.access_level > b.access_level ? -1 : 0
          ),
          "id"
        ),
        loading: false,
      }
    case FETCH_SPHERES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    case REORDER_SPHERES_SUCCESS:
      return {
        ...state,
        spheres_order: action.spheres_order,
      }
    case REORDER_SPHERES_FAIL:
      return {
        ...state,
        error: action.error,
      }
    case RENAME_SPHERE_SUCCESS:
      return {
        ...state,
      }
    case RENAME_SPHERE_FAIL:
      return {
        ...state,
        error: action.error,
      }
    case FETCH_TOPICS_SUCCESS:
      return {
        ...state,
        topics: action.topics,
      }
    case FETCH_POPULAR_TOPICS_SUCCESS:
      return {
        ...state,
        popular_topics: action.topics,
      }
    case FETCH_TOPICS_FAIL:
      return {
        ...state,
        error: action.error,
      }
    case FETCH_EXPERTISES_SUCCESS:
      return {
        ...state,
        expertises: action.expertises,
      }
    case FETCH_POPULAR_EXPERTISES_SUCCESS:
      return {
        ...state,
        popular_expertises: action.expertises,
      }
    case FETCH_EXPERTISES_FAIL:
      return {
        ...state,
        error: action.error,
      }
    case FETCH_CONNEXIONS_SUCCESS:
      return {
        ...state,
        connexions: action.connexions,
      }
    case FETCH_CONNEXIONS_FAIL:
      return {
        ...state,
        error: action.error,
      }
    case FETCH_SOURCES_SUCCESS:
      return {
        ...state,
        sources: action.sources,
      }
    case FETCH_SOURCES_FAIL:
      return {
        ...state,
        error: action.error,
      }
    case FETCH_POPULAR_SOURCES_SUCCESS:
      return {
        ...state,
        popular_sources: action.sources,
      }
    case FETCH_COMPANIES_SUCCESS:
      return {
        ...state,
        companies: action.companies,
      }
    case FETCH_POPULAR_COMPANIES_SUCCESS:
      return {
        ...state,
        popular_companies: action.companies,
      }
    case FETCH_COMPANIES_FAIL:
      return {
        ...state,
        error: action.error,
      }
    case FETCH_LOCATIONS_SUCCESS:
      return {
        ...state,
        locations: action.locations,
      }
    case FETCH_POPULAR_LOCATIONS_SUCCESS:
      return {
        ...state,
        popular_locations: action.locations,
      }
    case FETCH_LOCATIONS_FAIL:
      return {
        ...state,
        error: action.error,
      }
    case ADD_RULE:
      return {
        ...state,
        loading: true,
      }
    case ADD_RULE_SUCCESS:
      return {
        ...state,
        loading: false,
        spheres: updateSpheres(state, action),
      }
    case ADD_RULE_FAIL:
      return {
        ...state,
        loading: false,
      }
    case UPDATE_RULE_SUCCESS:
      return {
        ...state,
        spheres: updateRule(state, action),
        loading: false,
      }
    case UPDATE_RULE:
      return {
        ...state,
        loading: true,
      }

    case UPDATE_RULE_FAIL:
      return {
        ...state,
        loading: false,
      }
    case DELETE_SPHERE_SUCCESS:
      return {
        ...state,
        spheres: state.spheres.filter((sphere) => +sphere.id !== +action.sphere.id),
        spheres_order: state.spheres_order.filter(
          (sphere_id) => +sphere_id !== +action.sphere.id
        ),
      }
    case FETCH_COLLECTION:
      return {
        ...state,
        loading: true,
      }

    case FETCH_COLLECTION_FAIL:
      return {
        ...state,
        loading: false,
      }
    case FETCH_COLLECTION_SUCCESS:
      return {
        ...state,
        collection: action.collection,
        collectionSharings: action.collectionSharings,
        teamCollectionSharings: action.teamCollectionSharings,
        loading: false,
      }
    case DELETE_COLLECTION_SHARING_SUCCESS:
      return {
        ...state,
        collectionSharings: state.collectionSharings.filter(
          (cs) => cs.id !== action.collection_sharing_id
        ),
        teamCollectionSharings: state.teamCollectionSharings.filter(
          (cs) => cs.id !== action.collection_sharing_id
        ),
      }
    case MANAGE_GLOBAL_SHARING_SUCCESS:
      return {
        ...state,
        collectionSharings: updateSharings(state, action),
      }

    case MANAGE_PERSONAL_SHARING_SUCCESS:
      return {
        ...state,
        collectionSharings: updateSharings(state, action),
      }
    case MANAGE_TEAM_SHARING_SUCCESS:
      return {
        ...state,
        teamCollectionSharings: action.team_collection_sharings,
      }
    case SAVE_FLOW_SPHERE_SUCCESS:
      return {
        ...state,
        flowSphere: action.flow_sphere,
      }
    case FETCH_FLOW_SPHERE_SUCCESS:
      return {
        ...state,
        flowSphere: action.flow_sphere,
      }
    case LOGOUT_SUCCESS:
      return { ...INITIAL_STATE }
    case CLEAR_REDUX_COLLECTIONS_STATE:
      return {}
    case SET_INITIAL_REDUX_COLLECTIONS_STATE:
      return { ...INITIAL_STATE }
    default:
      return state
  }
}
