import React from "react"
import {
  ADD_INTERACTION_FAIL,
  ADD_INTERACTION_SUCCESS,
  ADD_NOTE_FAIL,
  ADD_NOTE_SUCCESS,
  DELETE_EVENT_FAIL,
  DELETE_EVENT_SUCCESS,
  DELETE_INTERACTION_FAIL,
  DELETE_INTERACTION_SUCCESS,
  DELETE_NOTE_FAIL,
  DELETE_NOTE_SUCCESS,
  UPDATE_NOTE_FAIL,
  UPDATE_NOTE_SUCCESS,
} from "./Interactions/Interactions.types"
import { notification } from "antd"
import {
  ADD_INTRODUCTION_FAIL,
  ADD_INTRODUCTION_SUCCESS,
  APPROVE_INTRODUCTION_FAIL,
  APPROVE_INTRODUCTION_SUCCESS,
  DELETE_INTRODUCTION_FAIL,
  DELETE_INTRODUCTION_SUCCESS,
  FETCH_INTRODUCTIONS_FAIL,
} from "./Introductions/Introductions.types"
import {
  ADD_ACCOUNT_FAIL,
  CREATE_STRIPE_SESSION_FAIL,
  DELETE_ACCOUNT_FAIL,
  DELETE_ACCOUNT_SUCCESS,
  FETCH_ACCOUNTS_FAIL,
  FETCH_CALENDARS_FAIL,
  RESYNC_INTERACTIONS_FAIL,
  RESYNC_INTERACTIONS_START,
  SHOW_COPIED_API_KEY_NOTIFICATION,
  SHOW_UNSUBSCRIBE_NOTIFICATION,
  UPDATE_CRITERIA_FAIL,
  UPDATE_CRITERIA_SUCCESS,
  UPDATE_SIGNATURE_FAIL,
  UPDATE_SIGNATURE_SUCCESS,
} from "./Accounts/Accounts.types"
import {
  ADD_NOTIFY_ABOUT_10000_RECORDS,
  APPROVE_DUPLICATE_FAIL,
  APPROVE_DUPLICATE_SUCCESS,
  APPROVE_ENRICHMENT_FAIL,
  APPROVE_ENRICHMENT_SUCCESS,
  CREATE_CONTACT_FAIL,
  CREATE_CONTACT_SUCCESS,
  DELETE_CONTACT_FAIL,
  DELETE_CONTACT_SUCCESS,
  DELETE_CONTACTS_SUCCESS,
  EDIT_CONTACT_FAIL,
  EDIT_CONTACT_SUCCESS,
  EDIT_CONTACTS_FAIL,
  EDIT_CONTACTS_SUCCESS,
  EXPORT_CONTACTS_FAIL,
  EXPORT_CONTACTS_SUCCESS,
  FETCH_DUPLICATES_FAIL,
  FETCH_ENRICHMENTS_FAIL,
  FETCH_PEOPLE_FAIL,
  IGNORE_DUPLICATE_FAIL,
  IGNORE_DUPLICATE_SUCCESS,
  IGNORE_ENRICHMENT_FAIL,
  IGNORE_ENRICHMENT_SUCCESS,
  MERGE_CONTACTS_FAIL,
  MERGE_CONTACTS_SUCCESS,
  UNARCHIVE_CONTACT_FAIL,
  UNARCHIVE_CONTACT_SUCCESS,
  UPDATE_CONTACT_IMAGE_FAIL,
  UPDATE_CONTACT_IMAGE_SUCCESS,
} from "./People/People.types"
import {
  ADD_PARTICIPANTS_TO_SPHERES_FAIL,
  ADD_PARTICIPANTS_TO_SPHERES_SUCCESS,
  ADD_TO_SPHERES_FAIL,
  ADD_TO_SPHERES_SUCCESS,
  ARCHIVE_PARTICIPANTS_FAIL,
  ARCHIVE_PARTICIPANTS_SUCCESS,
  FETCH_PARTICIPANTS_FAIL,
} from "./Participants/Participants.types"
import {
  ADD_RULE_FAIL,
  ADD_RULE_SUCCESS,
  ADD_SHARED_COLLECTION_FAIL,
  ADD_SHARED_COLLECTION_SUCCESS,
  CREATE_INITIAL_SPHERES_FAIL,
  CREATE_INITIAL_SPHERES_SUCCESS,
  CREATE_SPHERE_FAIL,
  CREATE_SPHERE_SUCCESS,
  DELETE_COLLECTION_SHARING_FAIL,
  DELETE_COLLECTION_SHARING_SUCCESS,
  DELETE_SPHERE_FAIL,
  DELETE_SPHERE_SUCCESS,
  FETCH_SPHERES_FAIL,
  MANAGE_GLOBAL_SHARING_FAIL,
  MANAGE_GLOBAL_SHARING_SUCCESS,
  MANAGE_PERSONAL_SHARING_FAIL,
  MANAGE_PERSONAL_SHARING_SUCCESS,
  MANAGE_TEAM_SHARING_FAIL,
  MANAGE_TEAM_SHARING_SUCCESS,
  REMOVE_SHARED_COLLECTION_FAIL,
  REMOVE_SHARED_COLLECTION_SUCCESS,
  RENAME_SPHERE_FAIL,
  RENAME_SPHERE_SUCCESS,
  SAVE_FLOW_SPHERE_FAIL,
  SAVE_FLOW_SPHERE_SUCCESS,
  SAVE_STAGE_SPHERE_FAIL,
  SAVE_STAGE_SPHERE_SUCCESS,
  UPDATE_RULE_FAIL,
  UPDATE_RULE_SUCCESS,
} from "./Collections/Collections.types"
import {
  ADD_TEAM_MEMBER_FAIL,
  ADD_TEAM_MEMBER_SUCCESS,
  AUTHENTICATE_USER_FAIL,
  CANCEL_TEAM_INVITE_FAIL,
  CANCEL_TEAM_INVITE_SUCCESS,
  CREATE_PARTNER_FAIL,
  CREATE_PARTNER_SUCCESS,
  CREATE_TEAM_FAIL,
  CREATE_TEAM_SUCCESS,
  DELETE_TEAM_FAIL,
  DELETE_TEAM_SUCCESS,
  FETCH_TEAM_FAIL,
  JOIN_TO_TEAM_FAIL,
  JOIN_TO_TEAM_SUCCESS,
  LEAVE_TEAM_FAIL,
  LEAVE_TEAM_SUCCESS,
  LOGOUT_ALL_DEVICES_FAIL,
  LOGOUT_ALL_DEVICES_SUCCESS,
  PASSWORD_RESET_FAIL,
  PASSWORD_RESET_SUCCESS,
  REGENERATE_API_KEY_FAIL,
  REGENERATE_API_KEY_SUCCESS,
  REGISTER_USER_FAIL,
  REMOVE_MEMBER_TEAM_FAIL,
  REMOVE_MEMBER_TEAM_SUCCESS,
  REMOVE_TEAM_INVITATION_FAIL,
  REMOVE_TEAM_INVITATION_SUCCESS,
  RESEND_TEAM_INVITATION_FAIL,
  RESEND_TEAM_INVITATION_SUCCESS,
  SAVE_PASSWORD_FAIL,
  SAVE_PASSWORD_SUCCESS,
  UPDATE_AVATAR_FAIL,
  UPDATE_AVATAR_SUCCESS,
  UPDATE_PARTNER_FAIL,
  UPDATE_PARTNER_SUCCESS,
  UPDATE_PROFILE_FAIL,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_TEAM_FAIL,
  UPDATE_TEAM_SETTINGS_FAIL,
  UPDATE_TEAM_SETTINGS_SUCCESS,
  UPDATE_TEAM_SUCCESS,
} from "./User/User.types"
import {
  ADD_REMINDER_FAIL,
  ADD_REMINDER_SUCCESS,
  COMPLETE_REMINDER_FAIL,
  COMPLETE_REMINDER_SUCCESS,
  DELETE_REMINDER_FAIL,
  DELETE_REMINDER_SUCCESS,
  IGNORE_SPECIAL_DATE_FAIL,
  IGNORE_SPECIAL_DATE_SUCCESS,
  UPDATE_REMINDER_FAIL,
  UPDATE_REMINDER_SUCCESS,
} from "./Reminders/Reminders.types"
import {
  APPROVE_TRIGGER_FAIL,
  APPROVE_TRIGGER_SUCCESS,
  IGNORE_TRIGGER_FAIL,
  IGNORE_TRIGGER_SUCCESS,
} from "./Triggers/Triggers.types"
import {
  setContactDrawerVisible,
  setSplitMessageSenderVisible,
  setVisibleContactID,
} from "./App/App.actions"
import { store } from "./store"
import {
  DELETE_SCHEDULED_SPLIT_MESSAGE_FAIL,
  DELETE_SCHEDULED_SPLIT_MESSAGE_SUCCESS,
  DELETE_TEMPLATE_FAIL,
  DELETE_TEMPLATE_SUCCESS,
  FETCH_AI_DRAFT,
  FETCH_AI_DRAFT_FAIL,
  FETCH_AI_DRAFT_SUCCESS,
  NEW_TEMPLATE_FAIL,
  NEW_TEMPLATE_SUCCESS,
  RESEND_MESSAGE_FAIL,
  RESEND_MESSAGE_SUCCESS,
  SAVE_TEMPLATE_FAIL,
  SAVE_TEMPLATE_SUCCESS,
  SCHEDULED_SPLIT_MESSAGE_FAIL,
  SCHEDULED_SPLIT_MESSAGE_SUCCESS,
  SEND_SPLIT_MESSAGE_FAIL,
  SEND_SPLIT_MESSAGE_SUCCESS,
  UPDATE_SCHEDULED_SPLIT_MESSAGE_FAIL,
  UPDATE_SCHEDULED_SPLIT_MESSAGE_SUCCESS,
  UPLOAD_MESSAGE_IMAGE_FAIL,
  UPLOAD_MESSAGE_IMAGE_SUCCESS,
} from "./Messages/Messages.types"
import { SHOW_NO_ACCOUNT_NOTIFICATION, VALIDATION_ERROR } from "./App/App.types"
import history from "../history"
import {
  ADD_EXTRA_TRIAL_DAYS_FAIL,
  ADD_EXTRA_TRIAL_DAYS_SUCCESS,
  CREATE_CHALLENGE_FAIL,
  CREATE_CHALLENGE_SUCCESS,
  FETCH_CHALLENGES_FAIL,
  SEND_WARNING_EMAIL,
  SEND_WARNING_EMAIL_FAIL,
  SET_AS_FREE_ACCOUNT_FAIL,
  SET_AS_FREE_ACCOUNT_SUCCESS,
  UPDATE_CHALLENGE_FAIL,
  UPDATE_CHALLENGE_SUCCESS,
  WIPE_ACCOUNTS_FAIL,
  WIPE_ACCOUNTS_SUCCESS,
} from "./Admin/Admin.types"
import {
  FETCH_ACTIVE_CHALLENGES_FAIL,
  FETCH_CHALLENGE_FAIL,
  JOIN_TO_CHALLENGE_FAIL,
  JOIN_TO_CHALLENGE_SUCCESS,
} from "./Activities/Activities.types"
import { setMessageDraft } from "./Messages/Messages.actions"

export const notificationsReducer = (_, action) => {
  switch (action.type) {
    case AUTHENTICATE_USER_FAIL:
      if (action.error.response?.status === 429) {
        notification.error({
          message: "Login",
          description:
            "Too many failed attempts. Your IP will be blocked for the next couple minutes.",
          className: "Notification-error",
        })
      } else {
        notification.error({
          message: "Login",
          description: "Failed to login. Check your credentials and try again.",
          className: "Notification-error",
        })
      }

      return null
    case REGISTER_USER_FAIL:
      if (action.error.response.status === 429) {
        notification.error({
          message: "Login",
          description:
            "Too many failed attempts. Your IP will be blocked for the next couple minutes.",
          className: "Notification-error",
        })
      } else {
        notification.error({
          message: "Register failed",
          description:
            "Something went wrong when trying to register your account. Try again or contact us if the problem persists.",
          className: "Notification-error",
        })
      }

      return null
    case UPDATE_PROFILE_SUCCESS:
      notification.success({
        message: "Profile",
        description: "Successfully updated profile.",
        className: "Notification-success",
      })
      return null
    case UPDATE_PROFILE_FAIL:
      if (action.error.response.status === 422) {
        const validationError = action.error.response.data.error
        notification.error({
          message: "Profile",
          description: validationError,
          className: "Notification-error",
        })
      } else {
        notification.error({
          message: "Profile",
          description:
            "Unable to update profile. Try again or contact us if the problem persists.",
          className: "Notification-error",
        })
      }
      return null
    case FETCH_ACCOUNTS_FAIL:
      notification.error({
        message: "Accounts",
        description:
          "Failed to download accounts from the database. Try refreshing the page or contact us if the problem persists.",
        className: "Notification-error",
      })
      return null
    case ADD_ACCOUNT_FAIL:
      notification.error({
        message: "Accounts",
        description:
          "Something went wrong when adding an account. Try again or contact us if the problem persists.",
        className: "Notification-error",
      })
      return null
    case FETCH_INTRODUCTIONS_FAIL:
      notification.error({
        message: "Introductions",
        description:
          "Failed to retrieve introductions from the database. Try refreshing the page or contact us if the problem persists.",
        className: "Notification-error",
      })
      return null
    case FETCH_PEOPLE_FAIL:
      notification.error({
        message: "People",
        description:
          "Failed to retrieve people from the database. Try refreshing the page or contact us if the problem persists.",
        className: "Notification-error",
      })
      return null
    case FETCH_PARTICIPANTS_FAIL:
      notification.error({
        message: "Participants",
        description:
          "Failed to retrieve participants from the database. Try refreshing the page or contact us if the problem persists.",
        className: "Notification-error",
      })
      return null
    case FETCH_SPHERES_FAIL:
      notification.error({
        message: "Collections",
        description:
          "Failed to retrieve spheres from the database. Try refreshing the page or contact us if the problem persists.",
        className: "Notification-error",
      })
      return null
    case ADD_TO_SPHERES_SUCCESS:
      notification.success({
        message: "Collections",
        description: (
          <div>
            <span>Added Sphere successfully.</span>
          </div>
        ),
        className: "Notification-success clickable",
        duration: 5,
        onClick: () => {
          store.dispatch(setContactDrawerVisible(true))
          store.dispatch(setVisibleContactID(action.person.id, action.person.slug))
          notification.destroy()
        },
      })
      return null
    case ADD_TO_SPHERES_FAIL:
      if (action.error.response.status === 422) {
        const validationError = action.error.response.data.error
        notification.error({
          message: "Collections",
          description: validationError,
          className: "Notification-error",
        })
      } else {
        notification.error({
          message: "Collections",
          description:
            "Failed to add Sphere to person. Try again or contact us if the problem persists.",
          className: "Notification-error",
        })
      }

      return null
    case ADD_NOTE_SUCCESS:
      notification.success({
        message: "Note",
        description: "Successfully added new note.",
        className: "Notification-success",
      })
      return null
    case ADD_NOTE_FAIL:
      notification.error({
        message: "Note",
        description:
          "Something went wrong when adding a note. Try again or contact us if the problem persists.",
        className: "Notification-error",
      })
      return null
    case UPDATE_PARTNER_SUCCESS:
      notification.success({
        message: "Partner",
        description: "Successfully updated partner.",
        className: "Notification-success",
      })
      return null
    case UPDATE_PARTNER_FAIL:
      notification.error({
        message: "Partner",
        description:
          "Something went wrong when updating a partner. Try again or contact us if the problem persists.",
        className: "Notification-error",
      })
      return null
    case CREATE_PARTNER_SUCCESS:
      notification.success({
        message: "Partner",
        description: "Successfully created new partner.",
        className: "Notification-success",
      })
      return null
    case CREATE_PARTNER_FAIL:
      notification.error({
        message: "Partner",
        description:
          "Something went wrong when creating a partner. Try again or contact us if the problem persists.",
        className: "Notification-error",
      })
      return null
    case ADD_INTERACTION_SUCCESS:
      notification.success({
        message: "Interaction",
        description: "Successfully added new interaction.",
        className: "Notification-success",
      })
      return null
    case ADD_INTERACTION_FAIL:
      notification.error({
        message: "Interaction",
        description:
          "Something went wrong when adding a interaction. Try again or contact us if the problem persists.",
        className: "Notification-error",
      })
      return null
    case ADD_INTRODUCTION_SUCCESS:
      notification.success({
        message: "Introduction",
        description: "Successfully added new introduction.",
        className: "Notification-success",
      })
      return null
    case ADD_INTRODUCTION_FAIL:
      notification.error({
        message: "Introduction",
        description:
          "Something went wrong when adding a introduction. Try again or contact us if the problem persists.",
        className: "Notification-error",
      })
      return null
    case EDIT_CONTACT_SUCCESS:
      notification.success({
        message: "Contact",
        description: (
          <div>
            <span>Successfully updated contact info.</span>
            <br />
          </div>
        ),
        className: "Notification-success clickable",
        duration: 5,
      })
      return null
    case EDIT_CONTACT_FAIL:
      if (action.error.response.status === 422) {
        const validationError = action.error.response.data.error
        notification.error({
          message: "Contact",
          description: validationError,
          className: "Notification-error",
        })
      } else {
        notification.error({
          message: "Contact",
          description:
            "Something went wrong when trying to update the contact. Try again or contact us if the problem persists.",
          className: "Notification-error",
        })
      }
      return null

    case APPROVE_INTRODUCTION_SUCCESS:
      notification.success({
        message: "Introduction",
        description: "Successfully approved introduction.",
        className: "Notification-success",
      })
      return null
    case APPROVE_INTRODUCTION_FAIL:
      notification.error({
        message: "Introduction",
        description:
          "Something went wrong when approving introduction. Try again or contact us if the problem persists.",
        className: "Notification-error",
      })
      return null
    case DELETE_INTRODUCTION_SUCCESS:
      notification.success({
        message: "Introduction",
        description: "Successfully deleted introduction.",
        className: "Notification-success",
      })
      return null
    case DELETE_INTRODUCTION_FAIL:
      notification.error({
        message: "Introduction",
        description:
          "Something went wrong when deleting introduction. Try again or contact us if the problem persists.",
        className: "Notification-error",
      })
      return null
    case ADD_REMINDER_SUCCESS:
      notification.success({
        message: "Task",
        description: "Successfully added task.",
        className: "Notification-success",
      })
      return null
    case ADD_REMINDER_FAIL:
      notification.error({
        message: "Task",
        description:
          "Something went wrong when adding a task. Try again or contact us if the problem persists.",
        className: "Notification-error",
      })
      return null
    case UPDATE_AVATAR_SUCCESS:
      notification.success({
        message: "Avatar",
        description: "Successfully updated avatar.",
        className: "Notification-success",
      })
      return null
    case UPDATE_AVATAR_FAIL:
      notification.error({
        message: "Avatar",
        description:
          "Something went wrong when updating avatar. Try again or contact us if the problem persists.",
        className: "Notification-error",
      })
      return null
    case UPDATE_CONTACT_IMAGE_SUCCESS:
      notification.success({
        message: "Contact",
        description: "Successfully updated contact's profile image.",
        className: "Notification-success",
      })
      return null
    case UPDATE_CONTACT_IMAGE_FAIL:
      notification.error({
        message: "Contact",
        description:
          "Something went wrong when updating contact. Try again or contact us if the problem persists.",
        className: "Notification-error",
      })
      return null
    case CREATE_CONTACT_SUCCESS:
      notification.success({
        message: "Contact",
        description: "Successfully created contact.",
        className: "Notification-success",
      })
      return null
    case CREATE_CONTACT_FAIL:
      notification.error({
        message: "Contact",
        description:
          "Something went wrong when creating contact. Try again or contact us if the problem persists.",
        className: "Notification-error",
      })
      return null
    case DELETE_CONTACT_SUCCESS:
      setTimeout(() => {
        notification.success({
          message: "Contact",
          description: "Successfully archived contact.",
          className: "Notification-success",
        })
      }, 500)
      return null
    case DELETE_CONTACTS_SUCCESS:
      setTimeout(() => {
        notification.success({
          message: "Contact",
          description: "Successfully archived contacts.",
          className: "Notification-success",
        })
      }, 500)
      return null
    case EDIT_CONTACTS_SUCCESS:
      setTimeout(() => {
        notification.success({
          message: "Contact",
          description: "Successfully updated contacts.",
          className: "Notification-success",
        })
      }, 500)
      return null
    case EDIT_CONTACTS_FAIL:
      if (action.error.response.status === 422) {
        const validationError = action.error.response.data.error
        notification.error({
          message: "Contact",
          description: validationError,
          className: "Notification-error",
        })
      } else {
        notification.error({
          message: "Contact",
          description:
            "Something went wrong when trying to update the contacts. Try again.",
          className: "Notification-error",
        })
      }
      return null
    case MERGE_CONTACTS_SUCCESS:
      setTimeout(() => {
        notification.success({
          message: "Contact",
          description: (
            <div>
              <span>Successfully merged contacts.</span>
              <br />
              <br />
              <span>
                <b>Click to show new contact.</b>
              </span>
            </div>
          ),
          className: "Notification-success clickable",
          duration: 5,
          onClick: () => {
            store.dispatch(setContactDrawerVisible(true))
            store.dispatch(setVisibleContactID(action.main.id, action.main.slug))
            notification.destroy()
          },
        })
      }, 500)
      return null
    case MERGE_CONTACTS_FAIL:
      setTimeout(() => {
        notification.error({
          message: "Contact",
          description:
            "Something went wrong when merging contacts. Try again or contact us if the problem persists.",
          className: "Notification-error",
        })
      }, 500)
      return null
    case DELETE_CONTACT_FAIL:
      notification.error({
        message: "Contact",
        description:
          "Something went wrong when deleting contact. Try again or contact us if the problem persists.",
        className: "Notification-error",
      })
      return null
    case DELETE_ACCOUNT_SUCCESS:
      notification.success({
        message: "Account",
        description:
          "Account has been queued for deletion. This process can take up to 30 days.",
        className: "Notification-success",
      })
      return null
    case DELETE_ACCOUNT_FAIL:
      notification.error({
        message: "Account",
        description:
          "Something went wrong when deleting account. Try again or contact us if the problem persists.",
        className: "Notification-error",
      })
      return null
    case RESYNC_INTERACTIONS_START:
      notification.success({
        message: "Account",
        description: "Successfully started synchronization.",
        className: "Notification-success",
      })
      return null
    case RESYNC_INTERACTIONS_FAIL:
      notification.error({
        message: "Account",
        description:
          "Something went wrong when synchronizing account. Try again or contact us if the problem persists.",
        className: "Notification-error",
      })
      return null
    case ADD_RULE_SUCCESS:
      notification.success({
        message: "Rules",
        description: "Successfully added new rule.",
        className: "Notification-success",
      })
      return null
    case ADD_RULE_FAIL:
      notification.error({
        message: "Rules",
        description:
          "Something went wrong when adding rule. Try again or contact us if the problem persists.",
        className: "Notification-error",
      })
      return null
    case UPDATE_RULE_SUCCESS:
      notification.success({
        message: "Rules",
        description: "Successfully updated rule.",
        className: "Notification-success",
      })
      return null
    case UPDATE_RULE_FAIL:
      notification.error({
        message: "Rules",
        description:
          "Something went wrong when updating rule. Try again or contact us if the problem persists.",
        className: "Notification-error",
      })
      return null
    case IGNORE_TRIGGER_SUCCESS:
      notification.success({
        message: "Trigger",
        description: "You ignored the trigger.",
        className: "Notification-success",
      })
      return null
    case IGNORE_TRIGGER_FAIL:
      notification.error({
        message: "Rules",
        description:
          "Something went wrong when ignoring trigger. Try again or contact us if the problem persists.",
        className: "Notification-error",
      })
      return null
    case APPROVE_TRIGGER_SUCCESS:
      notification.success({
        message: "Trigger",
        description: "You accepted the trigger so we created a new task for you.",
        className: "Notification-success",
      })
      return null
    case APPROVE_TRIGGER_FAIL:
      notification.error({
        message: "Trigger",
        description:
          "Something went wrong when approving trigger. Try again or contact us if the problem persists.",
        className: "Notification-error",
      })
      return null
    case COMPLETE_REMINDER_SUCCESS:
      notification.success({
        message: "Reminder",
        description: "You successfully marked reminder as completed.",
        className: "Notification-success",
      })
      return null
    case COMPLETE_REMINDER_FAIL:
      notification.error({
        message: "Reminder",
        description:
          "Something went wrong when updating reminder. Try again or contact us if the problem persists.",
        className: "Notification-error",
      })
      return null
    case DELETE_REMINDER_SUCCESS:
      notification.success({
        message: "Reminder",
        description: "You successfully deleted reminder.",
        className: "Notification-success",
      })
      return null
    case DELETE_REMINDER_FAIL:
      notification.error({
        message: "Reminder",
        description:
          "Something went wrong when deleting reminder. Try again or contact us if the problem persists.",
        className: "Notification-error",
      })
      return null
    case ARCHIVE_PARTICIPANTS_SUCCESS:
      notification.success({
        message: "Participant",
        description: "Successfully archived participants.",
        className: "Notification-success",
      })
      return null
    case ARCHIVE_PARTICIPANTS_FAIL:
      notification.error({
        message: "Participant",
        description:
          "Something went wrong when archiving participants. Try again or contact us if the problem persists.",
        className: "Notification-error",
      })
      return null
    case DELETE_SPHERE_SUCCESS:
      notification.success({
        message: "Spheres",
        description: `Successfully deleted ${action.sphere.title}.`,
        className: "Notification-success",
      })
      return null
    case DELETE_SPHERE_FAIL:
      notification.error({
        message: "Spheres",
        description:
          "Something went wrong when deleting sphere. Try again or contact us if the problem persists.",
        className: "Notification-error",
      })
      return null
    case UPDATE_NOTE_SUCCESS:
      notification.success({
        message: "Notes",
        description: `Successfully updated note.`,
        className: "Notification-success",
      })
      return null
    case UPDATE_NOTE_FAIL:
      notification.error({
        message: "Notes",
        description:
          "Something went wrong when updating note. Try again or contact us if the problem persists.",
        className: "Notification-error",
      })
      return null
    case DELETE_NOTE_SUCCESS:
      notification.success({
        message: "Notes",
        description: `Successfully deleted note.`,
        className: "Notification-success",
      })
      return null
    case DELETE_NOTE_FAIL:
      notification.error({
        message: "Notes",
        description:
          "Something went wrong when deleting note. Try again or contact us if the problem persists.",
        className: "Notification-error",
      })
      return null
    case DELETE_INTERACTION_SUCCESS:
      notification.success({
        message: "Interactions",
        description: `Successfully deleted interaction.`,
        className: "Notification-success",
      })
      return null
    case DELETE_INTERACTION_FAIL:
      notification.error({
        message: "Interactions",
        description:
          "Something went wrong when deleting interaction. Try again or contact us if the problem persists.",
        className: "Notification-error",
      })
      return null
    case DELETE_EVENT_SUCCESS:
      notification.success({
        message: "Events",
        description: `Successfully deleted event.`,
        className: "Notification-success",
      })
      return null
    case DELETE_EVENT_FAIL:
      notification.error({
        message: "Events",
        description:
          "Something went wrong when deleting event. Try again or contact us if the problem persists.",
        className: "Notification-error",
      })
      return null
    case UPDATE_SCHEDULED_SPLIT_MESSAGE_SUCCESS:
      notification.success({
        message: "Message",
        description: `Your scheduled split message was updated successfully!`,
        className: "Notification-success",
      })
      return null
    case UPDATE_SCHEDULED_SPLIT_MESSAGE_FAIL:
      notification.error({
        message: "Message",
        description:
          "Something went wrong when updating scheduled split message. Try again or contact us if the problem persists.",
        className: "Notification-error",
      })
      return null
    case SEND_SPLIT_MESSAGE_SUCCESS:
      notification.success({
        message: "Split Message",
        description: `Sending your messages shortly!`,
        className: "Notification-success",
      })
      return null
    case SEND_SPLIT_MESSAGE_FAIL:
      notification.error({
        message: "Split Message",
        description:
          "Something went wrong when sending messages. Try again or contact us if the problem persists.",
        className: "Notification-error",
      })
      return null
    case SCHEDULED_SPLIT_MESSAGE_SUCCESS:
      notification.success({
        message: "Split Message",
        description: `Your messages was scheduled successfully!`,
        className: "Notification-success",
      })
      return null
    case SCHEDULED_SPLIT_MESSAGE_FAIL:
      notification.error({
        message: "Split Message",
        description:
          "Something went wrong when scheduling messages. Try again or contact us if the problem persists.",
        className: "Notification-error",
      })
      return null
    case DELETE_SCHEDULED_SPLIT_MESSAGE_SUCCESS:
      notification.success({
        message: "Scheduled Message",
        description: `Your scheduled split message was canceled successfully!`,
        className: "Notification-success",
      })
      return null
    case DELETE_SCHEDULED_SPLIT_MESSAGE_FAIL:
      notification.error({
        message: "Scheduled Message",
        description:
          "Something went wrong when canceling scheduled split message. Try again or contact us if the problem persists.",
        className: "Notification-error",
      })
      return null
    case RESEND_MESSAGE_SUCCESS:
      notification.success({
        message: "Messages",
        description: `Messages were resent successfully!`,
        className: "Notification-success",
      })
      return null
    case RESEND_MESSAGE_FAIL:
      notification.error({
        message: "Messages",
        description:
          "Something went wrong when resending messages. Try again or contact us if the problem persists.",
        className: "Notification-error",
      })
      return null
    case NEW_TEMPLATE_FAIL:
      notification.error({
        message: "Message Templates",
        description:
          "Something went wrong when trying to add your template. Try again",
        className: "Notification-error",
      })
      return null
    case NEW_TEMPLATE_SUCCESS:
      notification.success({
        message: "Message Templates",
        description: `Message Template added!`,
        className: "Notification-success",
      })
      return null
    case CREATE_TEAM_FAIL:
      notification.error({
        message: "Teams",
        description: "Something went wrong when trying to add new team. Try again",
        className: "Notification-error",
      })
      return null
    case CREATE_TEAM_SUCCESS:
      notification.success({
        message: "Teams",
        description: `New team added!`,
        className: "Notification-success",
      })
      return null
    case DELETE_TEAM_FAIL:
      notification.error({
        message: "Teams",
        description:
          "Something went wrong when trying to remove the team. Try again",
        className: "Notification-error",
      })
      return null
    case DELETE_TEAM_SUCCESS:
      notification.success({
        message: "Teams",
        description: "Team removed!",
        className: "Notification-success",
      })
      return null
    case LEAVE_TEAM_FAIL:
      notification.error({
        message: "Teams",
        description: "Something went wrong when trying to leave the team. Try again",
        className: "Notification-error",
      })
      return null
    case LEAVE_TEAM_SUCCESS:
      notification.success({
        message: "Teams",
        description: `You left the team!`,
        className: "Notification-success",
      })
      return null
    case ADD_TEAM_MEMBER_FAIL:
      notification.error({
        message: "Teams",
        description:
          "Something went wrong when trying to invite new member. Try again",
        className: "Notification-error",
      })
      return null
    case ADD_TEAM_MEMBER_SUCCESS:
      notification.success({
        message: "Teams",
        description: `New member invited!`,
        className: "Notification-success",
      })
      return null
    case REMOVE_MEMBER_TEAM_FAIL:
      notification.error({
        message: "Teams",
        description:
          "Something went wrong when trying to remove the member. Try again",
        className: "Notification-error",
      })
      return null
    case REMOVE_MEMBER_TEAM_SUCCESS:
      notification.success({
        message: "Teams",
        description: "Member removed!",
        className: "Notification-success",
      })
      return null
    case DELETE_TEMPLATE_SUCCESS:
      notification.success({
        message: "Message Templates",
        description: `Message Template deleted!`,
        className: "Notification-success",
      })
      return null
    case DELETE_TEMPLATE_FAIL:
      notification.error({
        message: "Message Templates",
        description:
          "Something went wrong when trying to delete your template. Try again",
        className: "Notification-error",
      })
      return null
    case SAVE_TEMPLATE_SUCCESS:
      notification.success({
        message: "Message Templates",
        description: `Template updated!`,
        className: "Notification-success",
      })
      return null
    case SAVE_TEMPLATE_FAIL:
      notification.error({
        message: "Message Templates",
        description:
          "Something went wrong when trying to save your template. Try again",
        className: "Notification-error",
      })
      return null
    case PASSWORD_RESET_SUCCESS:
      notification.success({
        message: "Password reset",
        description: "Password reset link sent to your email!",
        className: "Notification-success",
      })
      return null
    case PASSWORD_RESET_FAIL:
      notification.error({
        message: "Password reset",
        description:
          "Something went wrong when trying to send you a reset password link. Please ensure you have entered an email for existing account and try again",
        className: "Notification-error",
      })
      return null
    case SAVE_PASSWORD_SUCCESS:
      notification.success({
        message: "Password reset",
        description: "Password saved! You can login with your new credentials.",
        className: "Notification-success",
      })
      return null
    case SAVE_PASSWORD_FAIL:
      notification.error({
        message: "Password reset",
        description:
          "Something went wrong when trying to save your password. Try again",
        className: "Notification-error",
      })
      return null
    case EXPORT_CONTACTS_SUCCESS:
      notification.success({
        message: "Export contacts",
        description:
          "Export started! We’ll e-mail your export file to you in the next few minutes!",
        className: "Notification-success",
      })
      return null
    case EXPORT_CONTACTS_FAIL:
      notification.error({
        message: "Export contacts",
        description:
          "Something went wrong when trying to export your contacts. Try again",
        className: "Notification-error",
      })
      return null
    case ADD_NOTIFY_ABOUT_10000_RECORDS:
      notification.info({
        message: "Select all matching search",
        description:
          'The "Select all matching searches" limit is 10,000. The first 10,000 records were selected.',
        className: "Notification-info",
      })
      return null
    case RENAME_SPHERE_SUCCESS:
      notification.success({
        message: "Spheres",
        description: "Sphere renamed!",
        className: "Notification-success",
      })
      return null
    case RENAME_SPHERE_FAIL:
      notification.error({
        message: "Spheres",
        description:
          "Something went wrong when trying to rename the sphere. Try again.",
        className: "Notification-error",
      })
      return null
    case SHOW_NO_ACCOUNT_NOTIFICATION:
      setTimeout(() => {
        notification.info({
          message: "Please connect an email account first",
          description: (
            <div>
              <span>
                Relatable sends messages through your existing email server. In order
                to send messages from Relatable, please connect (or re-connect) your
                email account.
              </span>
            </div>
          ),
          className: "Notification-info clickable",
          duration: 5,
          onClick: () => {
            history.push("/account/settings")

            notification.destroy()
          },
        })
      }, 500)
      return null
    case UPLOAD_MESSAGE_IMAGE_SUCCESS:
      notification.success({
        message: "Messages",
        description: "Image uploaded!",
        className: "Notification-success",
      })
      return null
    case UPLOAD_MESSAGE_IMAGE_FAIL:
      notification.error({
        message: "Messages",
        description:
          "Something went wrong when trying to upload the image. Try again.",
        className: "Notification-error",
      })
      return null
    case IGNORE_SPECIAL_DATE_SUCCESS:
      notification.success({
        message: "Special dates",
        description: "Special date dismissed!",
        className: "Notification-success",
      })
      return null
    case IGNORE_SPECIAL_DATE_FAIL:
      notification.error({
        message: "Special dates",
        description:
          "Something went wrong when trying to dismiss the special date. Try again.",
        className: "Notification-error",
      })
      return null
    case UPDATE_SIGNATURE_SUCCESS:
      notification.success({
        message: "Account",
        description: "Signature saved!",
        className: "Notification-success",
      })
      return null
    case UPDATE_SIGNATURE_FAIL:
      notification.error({
        message: "Account",
        description:
          "Something went wrong when trying to save the signature. Try again.",
        className: "Notification-error",
      })
      return null
    case UPDATE_TEAM_SUCCESS:
      notification.success({
        message: "Team",
        description: "Team updated!",
        className: "Notification-success",
      })
      return null
    case UPDATE_TEAM_FAIL:
      notification.error({
        message: "Team",
        description:
          "Something went wrong when trying to update the team. Try again.",
        className: "Notification-error",
      })
      return null
    case JOIN_TO_TEAM_SUCCESS:
      notification.success({
        message: "Team",
        description: "You have joined the team!",
        className: "Notification-success",
      })
      return null
    case JOIN_TO_TEAM_FAIL:
      notification.error({
        message: "Team",
        description:
          "Something went wrong when trying to join to the team. Try again.",
        className: "Notification-error",
      })
      return null
    case REMOVE_TEAM_INVITATION_SUCCESS:
      notification.success({
        message: "Team",
        description: "Team invitation removed!",
        className: "Notification-success",
      })
      return null
    case REMOVE_TEAM_INVITATION_FAIL:
      notification.error({
        message: "Team",
        description:
          "Something went wrong when trying to remove team invitation. Try again.",
        className: "Notification-error",
      })
      return null
    case CANCEL_TEAM_INVITE_SUCCESS:
      notification.success({
        message: "Team",
        description: "Team invitation removed!",
        className: "Notification-success",
      })
      return null
    case CANCEL_TEAM_INVITE_FAIL:
      notification.error({
        message: "Team",
        description:
          "Something went wrong when trying to remove team invitation. Try again.",
        className: "Notification-error",
      })
      return null
    case UPDATE_TEAM_SETTINGS_SUCCESS:
      notification.success({
        message: "Team",
        description: "Team settings updated!",
        className: "Notification-success",
      })
      return null
    case UPDATE_TEAM_SETTINGS_FAIL:
      notification.error({
        message: "Team",
        description:
          "Something went wrong when trying to update team settings. Try again.",
        className: "Notification-error",
      })
      return null
    case UPDATE_CRITERIA_SUCCESS:
      notification.success({
        message: "Account",
        description: "Criteria saved!",
        className: "Notification-success",
      })
      return null
    case UPDATE_CRITERIA_FAIL:
      notification.error({
        message: "Account",
        description:
          "Something went wrong when trying to save the criteria. Try again.",
        className: "Notification-error",
      })
      return null
    case RESEND_TEAM_INVITATION_SUCCESS:
      notification.success({
        message: "Account",
        description: "Criteria saved!",
        className: "Notification-success",
      })
      return null
    case RESEND_TEAM_INVITATION_FAIL:
      notification.error({
        message: "Account",
        description:
          "Something went wrong when trying to save the criteria. Try again.",
        className: "Notification-error",
      })
      return null
    case FETCH_CALENDARS_FAIL:
      notification.error({
        message: "Account",
        description:
          "Something went wrong when trying to fetch the calendars. Try again.",
        className: "Notification-error",
      })
      return null
    case SET_AS_FREE_ACCOUNT_SUCCESS:
      notification.success({
        message: "Account",
        description: "Account set as free!",
        className: "Notification-success",
      })
      return null
    case SET_AS_FREE_ACCOUNT_FAIL:
      notification.error({
        message: "Account",
        description:
          "Something went wrong when trying to set account as free. Try again.",
        className: "Notification-error",
      })
      return null
    case ADD_PARTICIPANTS_TO_SPHERES_SUCCESS:
      notification.success({
        message: "Uncategorized",
        description: "Added new contacts!",
        className: "Notification-success",
      })
      return null
    case ADD_PARTICIPANTS_TO_SPHERES_FAIL:
      if (action.error.response.status === 422) {
        const validationError = action.error.response.data.error
        notification.error({
          message: "Uncategorized",
          description: validationError,
          className: "Notification-error",
        })
      } else {
        notification.error({
          message: "Uncategorized",
          description:
            "Something went wrong when trying to add new contacts. Try again.",
          className: "Notification-error",
        })
      }

      return null
    case UNARCHIVE_CONTACT_SUCCESS:
      notification.success({
        message: "People",
        description: "Contact unarchived!",
        className: "Notification-success",
      })
      return null
    case UNARCHIVE_CONTACT_FAIL:
      notification.error({
        message: "People",
        description:
          "Something went wrong when trying to unarchive the contact. Try again.",
        className: "Notification-error",
      })
      return null
    case FETCH_DUPLICATES_FAIL:
      notification.error({
        message: "Duplicates",
        description:
          "Something went wrong when trying to unarchive the contact. Try again.",
        className: "Notification-error",
      })
      return null
    case APPROVE_DUPLICATE_FAIL:
      notification.error({
        message: "Duplicates",
        description:
          "Something went wrong when trying to approve the duplicate suggestion. Try again.",
        className: "Notification-error",
      })
      return null
    case APPROVE_DUPLICATE_SUCCESS:
      notification.success({
        message: "Duplicates",
        description: "Duplicate suggestion approved!",
        className: "Notification-success",
      })
      return null
    case IGNORE_DUPLICATE_FAIL:
      notification.error({
        message: "Duplicates",
        description:
          "Something went wrong when trying to ignore the duplicate suggestion. Try again.",
        className: "Notification-error",
      })
      return null
    case IGNORE_DUPLICATE_SUCCESS:
      notification.success({
        message: "Duplicates",
        description: "Duplicate suggestion ignored",
        className: "Notification-success",
      })
      return null
    case VALIDATION_ERROR:
      const validationErrors = action.errors
      const invalidFields = []
      if (validationErrors.spheres) {
        invalidFields.push("Spheres")
      }
      if (validationErrors.special_dates) {
        invalidFields.push("Special dates")
      }
      if (validationErrors.phone_numbers) {
        invalidFields.push("Phone numbers")
      }
      if (validationErrors.links) {
        invalidFields.push("Links")
      }
      if (validationErrors.email_addresses) {
        invalidFields.push("Email addresses")
      }
      if (validationErrors.birthday) {
        invalidFields.push("Birthday")
      }
      if (validationErrors.addresses) {
        invalidFields.push("Addresses")
      }
      notification.error({
        message: "Incorrect data",
        description:
          invalidFields.length > 0
            ? `Check these fields: ${invalidFields.join(", ")}`
            : "Check your form and try again",
        className: "Notification-error",
      })
      return null

    case DELETE_COLLECTION_SHARING_FAIL:
      notification.error({
        message: "Collection Sharing",
        description:
          "Something went wrong when trying to remove the collection sharing. Try again.",
        className: "Notification-error",
      })
      return null
    case DELETE_COLLECTION_SHARING_SUCCESS:
      notification.success({
        message: "Collection Sharing",
        description: "Collection sharing removed",
        className: "Notification-success",
      })
      return null

    case MANAGE_PERSONAL_SHARING_FAIL:
      notification.error({
        message: "Collection Sharing",
        description:
          "Something went wrong when trying update the collection sharing. Try again.",
        className: "Notification-error",
      })
      return null
    case MANAGE_PERSONAL_SHARING_SUCCESS:
      notification.success({
        message: "Collection Sharing",
        description: "Collection sharing updated!",
        className: "Notification-success",
      })
      return null

    case MANAGE_TEAM_SHARING_FAIL:
      notification.error({
        message: "Collection Sharing",
        description:
          "Something went wrong when trying update the collection sharing. Try again.",
        className: "Notification-error",
      })
      return null
    case MANAGE_TEAM_SHARING_SUCCESS:
      notification.success({
        message: "Collection Sharing",
        description: "Collection sharing updated!",
        className: "Notification-success",
      })
      return null

    case MANAGE_GLOBAL_SHARING_FAIL:
      notification.error({
        message: "Collection Sharing",
        description:
          "Something went wrong when trying to update the collection sharing. Try again.",
        className: "Notification-error",
      })
      return null
    case MANAGE_GLOBAL_SHARING_SUCCESS:
      notification.success({
        message: "Collection Sharing",
        description: "Collection sharing updated!",
        className: "Notification-success",
      })
      return null
    case ADD_SHARED_COLLECTION_FAIL:
      if (action.error.response.status === 422) {
        notification.error({
          message: "Collection Sharing",
          description: action.error.response.data[0],
          className: "Notification-error",
        })
      } else {
        notification.error({
          message: "Collections",
          description:
            "Something went wrong when trying to add the collection. Try again.",
          className: "Notification-error",
        })
      }
      return null
    case ADD_SHARED_COLLECTION_SUCCESS:
      notification.success({
        message: "Collections",
        description: "Collection added!",
        className: "Notification-success",
      })
      return null
    case REMOVE_SHARED_COLLECTION_FAIL:
      notification.error({
        message: "Collections",
        description:
          "Something went wrong when trying to remove the collection. Try again.",
        className: "Notification-error",
      })
      return null
    case REMOVE_SHARED_COLLECTION_SUCCESS:
      notification.success({
        message: "Collections",
        description: "Collection removed!",
        className: "Notification-success",
      })
      return null
    case ADD_EXTRA_TRIAL_DAYS_FAIL:
      notification.error({
        message: "Admin",
        description:
          "Something went wrong when trying to add extra days to trial. Try again.",
        className: "Notification-error",
      })
      return null
    case ADD_EXTRA_TRIAL_DAYS_SUCCESS:
      notification.success({
        message: "Admin",
        description: "Extra days added successfully!",
        className: "Notification-success",
      })
      return null
    case FETCH_ENRICHMENTS_FAIL:
      notification.error({
        message: "People Enrichment",
        description:
          "Something went wrong when trying to fetch people enrichment. Try again.",
        className: "Notification-error",
      })
      return null
    case APPROVE_ENRICHMENT_FAIL:
      notification.error({
        message: "People Enrichment",
        description:
          "Something went wrong when trying to approve the enrichment suggestion. Try again.",
        className: "Notification-error",
      })
      return null
    case APPROVE_ENRICHMENT_SUCCESS:
      notification.success({
        message: "People Enrichment",
        description: "Enrichment suggestion approved!",
        className: "Notification-success",
      })
      return null
    case IGNORE_ENRICHMENT_FAIL:
      notification.error({
        message: "People Enrichment",
        description:
          "Something went wrong when trying to ignore the enrichment suggestion. Try again.",
        className: "Notification-error",
      })
      return null
    case IGNORE_ENRICHMENT_SUCCESS:
      notification.success({
        message: "People Enrichment",
        description: "Enrichment suggestion ignored",
        className: "Notification-success",
      })
      return null
    case CREATE_SPHERE_FAIL:
      if (action.error.response.status === 422) {
        const validationError = action.error.response.data.error
        notification.error({
          message: "Spheres",
          description: validationError,
          className: "Notification-error",
        })
      } else {
        notification.error({
          message: "Spheres",
          description:
            "Something went wrong when trying to create the sphere. Try again.",
          className: "Notification-error",
        })
      }
      return null

    case CREATE_SPHERE_SUCCESS:
      notification.success({
        message: "Spheres",
        description: "Sphere created!",
        className: "Notification-success",
      })
      return null
    case SAVE_FLOW_SPHERE_FAIL:
      notification.error({
        message: "Flow Spheres",
        description:
          "Something went wrong when trying to update the flow sphere. Try again.",
        className: "Notification-error",
      })
      return null
    case SAVE_FLOW_SPHERE_SUCCESS:
      notification.success({
        message: "Flow Spheres",
        description: "Flow Sphere updated!",
        className: "Notification-success",
      })
      return null
    case FETCH_ACTIVE_CHALLENGES_FAIL:
      notification.error({
        message: "Challenges",
        description:
          "Failed to retrieve challenges from the database. Try refreshing the page or contact us if the problem persists.",
        className: "Notification-error",
      })
      return null
    case FETCH_CHALLENGE_FAIL:
      notification.error({
        message: "Challenge",
        description:
          "Failed to retrieve challenge from the database. Try refreshing the page or contact us if the problem persists.",
        className: "Notification-error",
      })
      return null
    case FETCH_TEAM_FAIL:
      notification.error({
        message: "Teams",
        description: "Something went wrong when trying to fetch teams. Try again.",
        className: "Notification-error",
      })
      return null
    case JOIN_TO_CHALLENGE_FAIL:
      notification.error({
        message: "Challenge",
        description:
          "Something went wrong when trying to join to the challenge. Try again.",
        className: "Notification-error",
      })
      return null
    case JOIN_TO_CHALLENGE_SUCCESS:
      notification.success({
        message: "Challenge",
        description: "You have joined the challenge!",
        className: "Notification-success",
      })
      return null
    case UPDATE_CHALLENGE_FAIL:
      notification.error({
        message: "Challenge",
        description:
          "Something went wrong when trying to update the challenge. Try again.",
        className: "Notification-error",
      })
      return null
    case UPDATE_CHALLENGE_SUCCESS:
      notification.success({
        message: "Challenge",
        description: "Challenge updated!",
        className: "Notification-success",
      })
      return null
    case CREATE_CHALLENGE_FAIL:
      notification.error({
        message: "Challenge",
        description:
          "Something went wrong when trying to create the challenge. Try again.",
        className: "Notification-error",
      })
      return null
    case CREATE_CHALLENGE_SUCCESS:
      notification.success({
        message: "Challenge",
        description: "Challenge created!",
        className: "Notification-success",
      })
      return null
    case FETCH_CHALLENGES_FAIL:
      notification.error({
        message: "Challenges",
        description:
          "Failed to retrieve challenges from the database. Try refreshing the page or contact us if the problem persists.",
        className: "Notification-error",
      })
      return null
    case UPDATE_REMINDER_SUCCESS:
      notification.success({
        message: "Task",
        description: "Successfully updated task.",
        className: "Notification-success",
      })
      return null
    case UPDATE_REMINDER_FAIL:
      notification.error({
        message: "Task",
        description:
          "Something went wrong when updating a task. Try again or contact us if the problem persists.",
        className: "Notification-error",
      })
      return null
    case SAVE_STAGE_SPHERE_SUCCESS:
      notification.success({
        message: "Stage Spheres",
        description: "Stage Sphere updated!",
        className: "Notification-success",
      })
      return null
    case SAVE_STAGE_SPHERE_FAIL:
      notification.error({
        message: "Stage Spheres",
        description:
          "Something went wrong when trying to update the stage sphere. Try again.",
        className: "Notification-error",
      })
      return null
    case SHOW_UNSUBSCRIBE_NOTIFICATION:
      notification.success({
        message: "Unsubscribe",
        description: "You have been unsubscribed from the email reminders.",
        className: "Notification-success",
      })
      return null
    case CREATE_INITIAL_SPHERES_FAIL:
      notification.error({
        message: "Spheres",
        description:
          "Something went wrong when trying to create initial spheres. Try again.",
        className: "Notification-error",
      })
      return null
    case CREATE_INITIAL_SPHERES_SUCCESS:
      notification.success({
        message: "Spheres",
        description: "Initial spheres created!",
        className: "Notification-success",
      })
      return null
    case LOGOUT_ALL_DEVICES_SUCCESS:
      notification.success({
        message: "Logout",
        description: "You have been logged out from all devices.",
        className: "Notification-success",
      })
      return null
    case LOGOUT_ALL_DEVICES_FAIL:
      notification.error({
        message: "Logout",
        description:
          "Something went wrong when trying to logout from all devices. Try again.",
        className: "Notification-error",
      })
      return null
    case CREATE_STRIPE_SESSION_FAIL:
      notification.error({
        message: "Stripe",
        description: "Something went wrong when trying to open Stripe Session.",
        className: "Notification-error",
      })
      return null
    case FETCH_AI_DRAFT:
      notification.info({
        className: "Notification-info",
        message: "AI Draft",
        description:
          "Generating AI Draft! You can close the modal and continue working. We will notify you when it's ready.",
      })
      return null
    case FETCH_AI_DRAFT_FAIL:
      notification.error({
        className: "Notification-error",
        message: "AI Draft",
        description:
          "Something went wrong when trying to fetch AI Draft. Try again.",
      })
      return null
    case FETCH_AI_DRAFT_SUCCESS:
      notification.destroy()
      notification.success({
        className: "Notification-success clickable",
        message: "AI Draft",
        description: (
          <div>
            <span>AI Draft fetched! You can access it by clicking link below.</span>
            <br />
            <span>
              <b>Click to show a draft.</b>
            </span>
          </div>
        ),
        onClick: () => {
          store.dispatch(setSplitMessageSenderVisible(true))
          store.dispatch(setMessageDraft(action.aiDraft))
          store.dispatch(setVisibleContactID(action.person_id))
          notification.destroy()
        },
      })
      return null

    case WIPE_ACCOUNTS_SUCCESS:
      notification.success({
        message: "Admin",
        description: "Accounts have been wiped.",
        className: "Notification-success",
      })
      return null

    case WIPE_ACCOUNTS_FAIL:
      notification.error({
        message: "Admin",
        description: "Something went wrong when trying to wipe accounts.",
        className: "Notification-error",
      })
      return null

    case SEND_WARNING_EMAIL:
      notification.success({
        message: "Admin",
        description: "Warning emails has been sent.",
        className: "Notification-success",
      })
      return null

    case SEND_WARNING_EMAIL_FAIL:
      notification.error({
        message: "Admin",
        description: "Something went wrong when trying to send warning email.",
        className: "Notification-error",
      })
      return null

    case SHOW_COPIED_API_KEY_NOTIFICATION:
      notification.success({
        message: "API Key",
        description: "API Key copied to clipboard!",
        className: "Notification-success",
      })
      return null

    case REGENERATE_API_KEY_SUCCESS:
      notification.success({
        message: "API Key",
        description: "API Key generated successfully!",
        className: "Notification-success",
      })
      return null

    case REGENERATE_API_KEY_FAIL:
      notification.error({
        message: "API Key",
        description: "API Key generation failed.",
        className: "Notification-error",
      })
      return null
    default:
      return null
  }
}
