import "./MessageForm.styles.scss"
import "../../MessageCreator.styles.scss"
import React, { useEffect, useState } from "react"
import {
  Button,
  Col,
  Divider,
  Dropdown,
  Form,
  Input,
  Menu,
  Popover,
  Row,
  Select,
  Spin,
} from "antd"
import { useDispatch, useSelector } from "react-redux"
import Icon from "@ant-design/icons"
import moment from "moment"
import {
  fetchAiDraft,
  fetchTemplate,
  fetchTemplates,
} from "../../../../../../redux/Messages/Messages.actions"
import CCMultiSelect from "../../../../CCMultiSelect/CCMultiSelect.component"
import { ReactComponent as WavingHand } from "../../../../../../images/WavingHand.svg"
import HandshakeIcon from "../../../../../Icons/Handshake.icons"
import { toggleSuggestedMessagesPanel } from "../../../../../../redux/App/App.actions"
import FileLines from "../../../../../Icons/FileLines.icons"
import Brain from "../../../../../Icons/Brain.icons"
import HandshakeAngle from "../../../../../Icons/HandshakeAngle.icons"
import WandMagicSparkles from "../../../../../Icons/WandMagicSparkles.icons"
import RelatableLoader from "../../../../RelatableLoader/RelatableLoader.component"
import DefaultScreen from "../DefaultScreen.component"

const toneOptions = [
  { label: "Friendly", value: "friendly" },
  { label: "Casual", value: "casual" },
  { label: "Business Casual", value: "business_casual" },
  { label: "Formal", value: "formal" },
  { label: "Aggressive", value: "aggressive" },
  { label: "Groovy", value: "groovy" },
]

const generalSubjectOptions = [
  { label: "Just thinking of you", value: "just_thinking_of_you" },
  { label: "Catching up", value: "catching_up" },
  { label: "Coffee invite", value: "coffee_invite" },
  { label: "Call invite", value: "call_invite" },
  { label: "Referral request", value: "referral_request" },
  { label: "Is a hot dog a sandwich?", value: "is_a_hot_dog_a_sandwich" },
]

const MessageForm = ({
  message,
  updateMessage,
  changeSelectedAccount,
  invalidReceivers = [],
  receiver,
  ccReceivers,
  setCcReceivers,
  supportAIDrafting,
  setCcReceiversForEach,
  splitMessage,
  includedRelatedContacts,
  isDefaultScreenVisible,
  closeDefaultScreen,
}) => {
  const [form] = Form.useForm()

  const dispatch = useDispatch()
  const token = useSelector((state) => state.UserState.access_token)
  const userAccounts = useSelector((state) => state.AccountsState.accounts)
  const templates = useSelector((state) => state.Messages.templates)
  const loading = useSelector((state) => state.Messages.loading)
  const generatingDraft = useSelector((state) => state.Messages.generatingDraft)

  const visible = useSelector((state) => state.AppState.messageSenderVisible)
  const contactId = useSelector((state) => state.AppState.visibleContactID)
  const initCC = useSelector((state) => state.AppState.initCC)
  const selectedContacts = useSelector((state) => state.AppState.selected_contacts)
  const contact = useSelector(
    (state) =>
      state.PeopleState.people &&
      state.PeopleState.people.length &&
      state.PeopleState.people.find((p) => p.id === contactId)
  )
  const user = useSelector((state) => state.UserState)

  const [selectedTone, setSelectedTone] = useState("friendly")
  const [selectedSubject, setSelectedSubject] = useState("just_thinking_of_you")
  const [AIDraftPopoverOpen, setAIDraftPopoverOpen] = useState(false)
  const [templatesMenuVisible, setTemplatesMenuVisible] = useState(false)

  useEffect(() => {
    if (initCC?.length > 0) {
      const tempCC = []
      selectedContacts.forEach((contact) => {
        const email = contact?.email_addresses && contact.email_addresses[0]
        if (email?.value?.trim()?.length > 0) {
          tempCC.push(email.value)
        }
      })

      setCcReceivers(tempCC)
    }
    // eslint-disable-next-line
  }, [initCC])

  useEffect(() => {
    if (token && visible) {
      form.resetFields(["body", "subject", "from"])
    }
    setSelectedTone(localStorage.getItem("aiDraftTone") || "friendly")
    setSelectedSubject(
      localStorage.getItem("aiDraftGeneralSubject") || "just_thinking_of_you"
    )

    // eslint-disable-next-line
  }, [visible])

  useEffect(() => {
    if (userAccounts && !message?.from) {
      const runningAccounts = userAccounts.filter((a) => a.state === "running")
      if (runningAccounts && runningAccounts.length) {
        let email = runningAccounts.find((a) => a.default)?.email
        if (email === undefined) {
          email = runningAccounts[0].email
        }
        form.setFieldsValue({ from: email })
      }
    }
    // eslint-disable-next-line
  }, [userAccounts])

  useEffect(() => {
    if (message) {
      form.setFieldsValue({
        subject: message.subject,
        to: message.to,
        from: message.from,
      })
    }
    // eslint-disable-next-line
  }, [message])

  const handleChangeSelectedAccount = (v) => {
    changeSelectedAccount(v)
  }

  const handleChangeSelectedTemplate = (t) => {
    dispatch(fetchTemplate(t.id))
  }
  const AIDraftContent = (
    <div className={"MessageForm_AIPopover"}>
      <p>
        We'll use AI to generate a first draft of your message, for you to then edit
        and send.
        <br />
        <a
          href="https://relatable.helpscoutdocs.com/article/38-ai-drafting"
          target="_blank"
          rel="noopener noreferrer"
        >
          Read more about how this works
        </a>
      </p>
      <p className={"MessageForm_Info"}>
        <Icon component={WavingHand} /> We use the
        <a href={"/account/settings"} target="_blank" rel="noopener noreferrer">
          {" "}
          bio field in your profile{" "}
        </a>
        to help customize your message.
      </p>

      <Select
        value={selectedTone}
        onSelect={(val, option) => {
          //   save choice in the local storage
          localStorage.setItem("aiDraftTone", option.value)
          setSelectedTone(option.value)
        }}
        options={toneOptions}
      />
      <Select
        value={selectedSubject}
        onSelect={(val, option) => {
          //   save choice in the local storage
          setSelectedSubject(option.value)
          localStorage.setItem("aiDraftGeneralSubject", option.value)
        }}
        options={generalSubjectOptions}
      />
      <Button
        onClick={() => {
          const subject = generalSubjectOptions.find(
            (s) => s.value === selectedSubject
          )
          const tone = toneOptions.find((t) => t.value === selectedTone)

          if (splitMessage) {
            dispatch(fetchAiDraft(null, tone, subject))
          } else {
            dispatch(fetchAiDraft(contactId, tone, subject))
          }
          setAIDraftPopoverOpen(false)
        }}
        type={"primary"}
      >
        Generate!
      </Button>
    </div>
  )
  const templatesMenu = (
    <Menu className={"MessageForm_Dropdown"}>
      <RelatableLoader loading={loading}></RelatableLoader>
      {templates?.length ? (
        templates.map((t) => (
          <Menu.Item
            onClick={(e) => {
              handleChangeSelectedTemplate(t)
              setTemplatesMenuVisible(false)
            }}
            key={t.id}
          >
            <Row style={{ margin: "0.1rem 1rem 0 0" }}>
              <Col className={"TemplateCol"} md={14}>
                {t.subject}
              </Col>
              <Col style={{ color: "#acacac" }} md={6} span={4}>
                {moment(t.timestamp).tz(user.time_zone).format("MMMM Do")}
              </Col>
            </Row>
          </Menu.Item>
        ))
      ) : (
        <Menu.Item>No templates found</Menu.Item>
      )}
    </Menu>
  )

  return (
    <>
      {isDefaultScreenVisible ? (
        <DefaultScreen
          exitDefaultScreen={(type) => {
            closeDefaultScreen(type)
            if (type === "templates") {
              dispatch(fetchTemplates())
              setTemplatesMenuVisible(true)
            } else if (type === "suggestions") {
              dispatch(toggleSuggestedMessagesPanel(true))
            }
          }}
        />
      ) : (
        <Form
          onValuesChange={(values) => {
            updateMessage({ ...message, ...values })
          }}
          className={"MessageForm_FormWrapper"}
          labelAlign={"left"}
          wrapperCol={{ sm: { span: 24 }, md: { span: 24 }, lg: { span: 24 } }}
          form={form}
          layout={"horizontal"}
        >
          <Form.Item name={"from"}>
            <Select
              bordered={false}
              placeholder="From"
              style={{ width: "100%" }}
              onChange={(v) => handleChangeSelectedAccount(v)}
            >
              {userAccounts &&
                userAccounts.length &&
                userAccounts
                  .filter((a) => a.email && a.email.length && a.state === "running")
                  .map(({ email }, idx) => (
                    <Select.Option key={idx} value={email}>
                      {email}
                    </Select.Option>
                  ))}
            </Select>
          </Form.Item>
          <Divider />
          {contact ? (
            <div className="MessageForm__ToAndCC">
              <div>
                <Form.Item name={"to"}>
                  <Select bordered={false} placeholder="Recipients">
                    {contact &&
                      contact.email_addresses &&
                      contact.email_addresses
                        .filter((e) => e && e.value && e.value.trim().length > 0)
                        .map((email, idx) => {
                          return (
                            <Select.Option key={idx} value={email.value}>
                              <span className={"MessageForm__text-mail"}>
                                {email.value}
                              </span>{" "}
                              {email && email.name && email.name.trim().length && (
                                <span className={"MessageForm__text-mail-type"}>
                                  ({email.name})
                                </span>
                              )}
                            </Select.Option>
                          )
                        })}
                  </Select>
                </Form.Item>
                <Divider
                  className={`${
                    invalidReceivers.includes(contact.id) && "InvalidSelect"
                  }`}
                />
              </div>
              <div>
                <Form.Item name={"cc"}>
                  <CCMultiSelect
                    selectedPeople={ccReceivers}
                    setSelectedPeople={setCcReceivers}
                  />
                </Form.Item>
                <Divider />
              </div>
            </div>
          ) : (
            initCC?.length > 0 && (
              <div>
                <Form.Item name={"cc"}>
                  <CCMultiSelect
                    selectedPeople={ccReceivers}
                    setSelectedPeople={setCcReceivers}
                  />
                </Form.Item>
                <Divider />
              </div>
            )
          )}

          <Form.Item name={"subject"}>
            <Input bordered={false} placeholder="Subject" />
          </Form.Item>
          <Divider />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              columnGap: "20px",
            }}
          >
            <div className={"MessageForm_Link"}>
              <Dropdown
                onVisibleChange={(visible) => {
                  if (visible) dispatch(fetchTemplates())
                  setTemplatesMenuVisible(visible)
                }}
                disabled={!!receiver && splitMessage}
                overlay={templatesMenu}
                trigger={["click"]}
                visible={templatesMenuVisible}
              >
                <button
                  className={"MessageForm_Link_Related"}
                  onClick={(e) => {
                    setTemplatesMenuVisible(true)
                    e.preventDefault()
                  }}
                >
                  <Icon component={FileLines} />
                  <span>Templates</span>
                </button>
              </Dropdown>
            </div>
            <div className={"MessageForm_Link"}>
              <Popover
                content={AIDraftContent}
                trigger={["click"]}
                visible={AIDraftPopoverOpen}
                placement={"bottomRight"}
                onVisibleChange={(visible) => {
                  setAIDraftPopoverOpen(visible)
                }}
              >
                <button
                  disabled={!!receiver && splitMessage}
                  className={"ant-dropdown-trigger"}
                  onClick={(e) => {
                    setAIDraftPopoverOpen(true)
                    e.preventDefault()
                  }}
                >
                  {generatingDraft ? (
                    <>
                      <Spin /> Generating
                    </>
                  ) : (
                    <>
                      <Icon component={Brain} style={{ marginRight: "4px" }} />
                      <span>AI draft</span>
                    </>
                  )}
                </button>
              </Popover>
            </div>
            <div className={"MessageForm_Link"}>
              {splitMessage ? (
                <button
                  disabled={!!receiver || includedRelatedContacts}
                  className={"ant-dropdown-trigger MessageForm_Link_Related"}
                  onClick={(e) => {
                    setCcReceiversForEach()
                  }}
                >
                  <Icon component={HandshakeIcon} />
                  <span>
                    {includedRelatedContacts ? "Added related" : "Include related"}
                  </span>
                </button>
              ) : (
                <button
                  disabled={!contact?.connexions?.length}
                  className={"ant-dropdown-trigger MessageForm_Link_Related"}
                  onClick={(e) => {
                    const connexions_emails = contact?.connexions
                      .map((c) => c.email)
                      .filter(Boolean)

                    setCcReceivers([
                      ...ccReceivers,
                      ...connexions_emails.filter((ce) => !ccReceivers.includes(ce)),
                    ])
                  }}
                >
                  <Icon component={HandshakeAngle} /> <span>Add related</span>
                </button>
              )}
            </div>
            <div className={"MessageForm_Link"}>
              <button
                disabled={!!receiver && splitMessage}
                className={"ant-dropdown-trigger MessageForm_Link_Related"}
                onClick={(e) => {
                  dispatch(toggleSuggestedMessagesPanel())
                }}
              >
                <Icon component={WandMagicSparkles} />
                <span>Suggestions</span>
              </button>
            </div>
          </div>
        </Form>
      )}
    </>
  )
}

export default MessageForm
