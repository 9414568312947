import React, { useEffect, useState } from "react"

import SharedTable from "../../components/SharedCollection/SharedTable.component"
import { useParams } from "react-router"
import { Avatar, Button } from "antd"
import { UserOutlined } from "@ant-design/icons"
import { fetchSharingDetails } from "../SharedCollection/SharedCollection.api"
import { useDispatch, useSelector } from "react-redux"
import {
  addSharedCollection,
  removeSharedCollection,
} from "../../redux/Collections/Collections.actions"

const SpecificallySharedCollection = () => {
  const params = useParams()
  const dispatch = useDispatch()
  const [sharedCollection, setSharedCollection] = useState(null)
  const [loaded, setLoaded] = useState(false)

  const sharedSpheres = useSelector((state) => state.CollectionsState.shared_spheres)

  useEffect(() => {
    if (params.token) {
      fetchSharingDetails(params.token, (sharingDetails) => {
        setLoaded(true)
        setSharedCollection(sharingDetails)
      }).catch((error) => {
        setLoaded(true)
        setSharedCollection(null)
      })
    }
  }, [params.token, sharedSpheres])

  const renderDescription = (collection) => {
    return `${collection.shared_by} has given ${
      collection.global ? "anyone with the link" : "you"
    } permission to see ${
      collection.access_level >= 1
        ? "all information(including notes & interactions)"
        : "basic information"
    } about this relationships
             ${
               collection.access_level === 2 ? " and ability to add/edit them." : "."
             }`
  }

  return (
    <div>
      {loaded &&
        (sharedCollection?.has_access ? (
          <div className={"SharedCollection_Header"}>
            <span className={"SharedCollection_Title"}>
              {sharedCollection.title}{" "}
            </span>
            <div className={"SharedCollection_Owner"}>
              <Avatar icon={<UserOutlined />} />
              <span className={"SharedCollection_Description"}>
                {renderDescription(sharedCollection)}
              </span>
            </div>
            {!sharedCollection.team_id &&
              (sharedCollection.already_added ? (
                <Button
                  type={"primary"}
                  className={"SharedCollection_IgnoreButton"}
                  onClick={() => {
                    dispatch(removeSharedCollection(sharedCollection.id))
                  }}
                >
                  Remove collection
                </Button>
              ) : (
                <Button
                  type={"primary"}
                  className={"SharedCollection_AddButton"}
                  onClick={() => {
                    dispatch(addSharedCollection(params.token))
                  }}
                >
                  Add to my Relatable
                </Button>
              ))}
          </div>
        ) : (
          <div className={"SharedCollection_Header"}>
            <span className={"SharedCollection_Title"}>Not found</span>
            <span className={"SharedCollection_Description"}>
              We couldn’t find that Sphere{" "}
            </span>
          </div>
        ))}
      <SharedTable
        global={false}
        token={params.token}
        activeCollection={sharedCollection}
      />
    </div>
  )
}

export default SpecificallySharedCollection
